import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../Components/Common/Breadcrumb";
import useApi from "../../hooks/useApi";
import {fetchData} from "../../BL/AllocationGroup";
import {toast, ToastContainer} from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import DataGrid from "../../Components/Common/DataGrid";
import {allocationGroupColDef} from "../../uihelper/gridutil";
import {useLocalStorage} from "../../hooks/useLocalStorage";

const endpoints = {
    getAll: 'api/AllocationGroupManagement/AllocationGroup',
    getById: 'api/AllocationGroupManagement/AllocationGroup',
    addData: 'api/AllocationGroupManagement/AllocationGroup',
    updateData: 'api/AllocationGroupManagement/AllocationGroup',
    deleteData: 'api/AllocationGroupManagement/AllocationGroup',
};

const AllocationGroup = () => {
    const [allocationGroupsData, setAllocationGroupsData] = useState<any[]>([]);
    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');

    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    //const { getDataByParams } = useApi(endpoints,'/api/' + baseUrl);
    const [loading, setLoading] = useState(false);
    const [clientId, setClientId] = useLocalStorage("id", "");
    const [partnershipId, setPartnershipId] = useLocalStorage("pid", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");
    const [selectedPartnership, setSelectedPartnership] = useLocalStorage("selectedPartnership", "");
    const refreshData = () => {
        setLoading(true);
        Promise.all([
            fetchData(getDataByParams, partnershipId, clientId),
        ])
            .then(([result]) => {
                setLoading(false);
                if(result.success) {
                    if(result.data.length > 0) {
                        setAllocationGroupsData(result.data);
                        toast.success("Data refreshed successfully!");
                    } else {
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        refreshData();
    }, []);
    const getBreadcrumbItems  = () => {
        breadcrumbItems.push({title: selectedClient.clientName + " (" + selectedClient.clientID + ")", url: "clienthome"});
        breadcrumbItems.push({title: selectedPartnership.partnershipName + " (" + selectedPartnership.partnershipID + ")", url: "partnershiphome"});
        return breadcrumbItems;
    }

    const breadcrumbItems =
        [
            {
                "title": "Home",
                "url": "Clients"
            }
        ]
    const onDataGridDelete = (data:any) => {
        console.log(data.data)
        deleteData(data?.data[0]?.id,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    };


    // update grid data
    const onDataGridUpdate = async (data:any) => {
        if(data.data.allocationAmount===null || data.data.allocationAmount===undefined)
            data.data.allocationAmount="";
        if (!data.data.taxPeriod || isNaN(Date.parse(data.data.taxPeriod))) {
            data.data.taxPeriod = ""; // Set to empty string if not a valid date
        }

        const result = await updateData(clientId,
            data.data,'id')
        if (result.success) {
            console.log('Data updated successfully:', result.data);
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to update data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while updating the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    }

    // add data
    const onDataGridAdd = async (rowData: any) => {
        rowData.data.clientID = clientId;
        rowData.data.partnershipID = partnershipId;

        delete rowData.data.id;
        if(rowData.data.allocationAmount===null || rowData.data.allocationAmount===undefined)
            rowData.data.allocationAmount="";
        if (!rowData.data.taxPeriod || isNaN(Date.parse(rowData.data.taxPeriod))) {
            rowData.data.taxPeriod = ""; // Set to empty string if not a valid date
        }

        const result = await addData(rowData.data);

        if (result.success) {
            console.log('Data added successfully:', result.data);
            toast.success("Data added successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to add data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while adding the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }

    };
    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader  />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <Breadcrumb items={getBreadcrumbItems()} title={selectedClient.clientName} breadcrumbItem="Allocation Group" />
                    <DataGrid  id={"allocgridPartner"} columns={allocationGroupColDef}
                               data={allocationGroupsData} editing={true} add={true} del={true} onAdd={onDataGridAdd}
                               onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                               pageType={"AllocationGroup"}
                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default AllocationGroup;
