export  interface columnDataSource{
    accessorName: string ;
    datasource: object[];
}

export  const partnershipColDataSources: columnDataSource[]  =[{
    accessorName: "isSubEntity", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},{
    accessorName: "isPTP", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},
    {
    accessorName: "election754", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},
    {
    accessorName: "interestType", datasource: [
        {name:'Percentage',value:'Percentage'},{name:'Units',value: 'Units'}
    ]},
]

export  const partnerColDataSources: columnDataSource[]  =[{
    accessorName: "partnerTypeID", datasource: [
        {name:'Individual',value:'Individual'},{name:'Business',value:'Business'}
    ]},{
    accessorName: "partnerTaxTypeID", datasource: [
        {name:'Individual',value:'Individual'},{name:'C Corporation',value:'CCorporation'},
        {name:'Sole Proprietor',value:'SoleProprietor'},{name:'LLC',value:'LLC'},
        {name:'LLP',value:'LLP'},{name:'Partnership',value:'Partnership'},
        {name:'S Corporation',value:'SCorporation'},{name:'IRA',value:'IRA'},
        {name:'Non Profit Corporation',value:'NonProfitCorporation'},{name:'Fund',value:'Fund'},
        {name:'DRE',value:'DRE'}
    ]},{
    accessorName: "isForeign", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},{
    accessorName: "isDRE", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},
    {
    accessorName: "gPorLP", datasource: [
        {name:'GP',value:'GP'},{name:'LP',value: 'LP'}
    ]},
    {
        accessorName: "isTieringPartner", datasource: [
            {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
        ]}
]

export  const contributionColDataSources: columnDataSource[]  =[{
    accessorName: "contributionType", datasource: [
        {name:'Asset',value:'Asset'},{name:'Cash',value: 'Cash'}
    ]},
]

export  const transferColDataSources: columnDataSource[]  =[{
    accessorName: "transferLot", datasource: [
        {name:'FIFO',value:'FIFO'},{name:'LIFO',value: 'LIFO'},{name:'Blended',value: 'Blended'},{name:'Specific',value: 'Specific'}
    ]},
]

export  const redemptionColDataSources: columnDataSource[]  =[{
    accessorName: "redemptionLot", datasource: [
        {name:'FIFO',value:'FIFO'},{name:'LIFO',value: 'LIFO'},{name:'Blended',value: 'Blended'},{name:'Specific',value: 'Specific'}
    ]},
]

export  const assetsColDataSources: columnDataSource[]  =[{
    accessorName: "acquisitionType", datasource: [
        {name:'Purchase',value:'Purchase'},{name:'Contribution',value: 'Contribution'},{name:'Exchange',value: 'Exchange'}
    ]},
    {
    accessorName: "newLife", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},
    {
    accessorName: "depreciationMethod", datasource: [
        {name:'MACRS',value:'MACRS'},{name:'Straight Line',value: 'SL'},{name:'Units of Consumption',value: 'Units of Consumption'},{name:'Depletion',value: 'Depletion'}
    ]},
    {
    accessorName: "convention", datasource: [
        {name:'Mid Month',value:'MM'},{name:'Half Year',value: 'HY'},{name:'Mid Quarter',value: 'MQ'}
    ]},
    {
    accessorName: "isRemedial", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},
    {
        accessorName: "isDepreciable", datasource: [
            {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
        ]},
    {
        accessorName: "dispositionType", datasource: [
            {name:'Sale',value:'Sale'},{name:'Distribution',value: 'Distribution'}
        ]},
    {
        accessorName: "depreciationAllocationMethod", datasource: [
            {name:'Traditional - Aggregated',value:'Traditional - Aggregated'},{name:'Traditional - Seggregated',value: 'Traditional - Seggregated'},{name:'Remedial - Seggregated',value: 'Remedial - Seggregated'},
            {name:'Remedial - Aggregated',value: 'Remedial - Aggregated'},{name:'Traditional Curative - Aggregated',value: 'Traditional Curative - Aggregated'},{name:'Traditional Curative - Seggregated',value: 'Traditional Curative - Seggregated'}
        ]},
    {
        accessorName: "gainAllocationMethod", datasource: [
            {name:'Traditional',value:'Traditional'},{name:'Remedial',value: 'Remedial'},{name:'Traditional Curative',value: 'Traditional Curative'}
        ]},
    {
        accessorName: "dispositionType", datasource: [
            {name:'Sale',value:'Sale'},{name:'Distribution',value: 'Distribution'}
        ]},
]

export  const incomeExpenseColDataSources: columnDataSource[]  =[{
    accessorName: "allocationMethod", datasource: [
        {name:'Prorata',value:'Prorata'},{name:'Special',value: 'Special'},{name:'704(b)',value: '704(b)'}
    ]},
]

export  const distributionColDataSources: columnDataSource[]  =[{
    accessorName: "distributionMethod", datasource: [
        {name:'Specific Partner',value: 'Specific Partner'},{name:'Prorata',value:'Prorata'},{name:'Special',value: 'Special'},{name:'704(b)',value: '704(b)'}
    ]},
    {
    accessorName: "distributionType", datasource: [
    {name:'Cash',value: 'Cash'},{name:'Asset',value:'Asset'}
    ]},
]


export function getDataSource(variableName: string): { [key: string]: any } | undefined {
    // Create a dictionary with variable names as keys and data sources as values
    const allColumnDataSources: { [key: string]: columnDataSource[] } = {
        partnershipColDataSources,
        partnerColDataSources,
        contributionColDataSources,
        transferColDataSources,
        redemptionColDataSources,
        assetsColDataSources,
        incomeExpenseColDataSources,
        distributionColDataSources
    };

    // Find the key that starts with the provided variable name
    const key = Object.keys(allColumnDataSources).find(k => k.startsWith(variableName));
    console.warn("Inside get datasources ...." , key);
    // If a key is found, return a dictionary where the keys are the accessorName and the values are the datasource
    // If no key is found, return undefined
    return key ? allColumnDataSources[key].reduce((acc, cur) => {
        acc[cur.accessorName] = cur.datasource;
        return acc;
    }, {}) : undefined;
}
