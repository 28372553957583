import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import useApi from "../../hooks/useApi";
import {fetchData} from "../../BL/Partnerships";
import {toast, ToastContainer} from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import DataGrid from "../../Components/Common/DataGrid";
import {partnershipColDef} from "../../uihelper/gridutil";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../../assets/css/custom.css';
import {partnershipColDataSources} from "../../uihelper/columndatasources";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import {validateAddress} from '../../helpers/validations_helper';

const endpoints = {
    getAll: 'Partnerships',
    getById: 'Partnerships',
    addData: 'Partnerships',
    updateData: 'Partnerships',
    deleteData: 'Partnerships',
};

const Partnerships = () => {
    const [partnershipData, setPartnershipData] = useState<any[]>([]);
    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [clientId, setClientId] = useLocalStorage("id", "");
    const [partnershipId, setPartnershipId] = useLocalStorage("pid", "");
    const [clientList, setClientList] = useLocalStorage("clients", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");
    const [selectedPartnership, setSelectedPartnership] = useLocalStorage("selectedPartnership", "");

    const refreshData = () => {
        setLoading(true);
        Promise.all([
            fetchData(getDataByParams, clientId),
        ])
            .then(([result]) => {
                if (result.success) {
                    if (result.data.length > 0) {
                        setPartnershipData(result.data);
                        toast.success("Data refreshed successfully!");
                    } else {
                        setPartnershipData([]);
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
                setLoading(false);
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        refreshData();
    }, [clientId]);

    const onDataGridDelete = (data:any) => {
        console.log(data.data)
        deleteData(data?.data[0]?.id,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    };
    const onDataGridUpdate = async (data:any) => {
        const errors = validateAddress(data.data.address);
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach(field => {
                toast.error(errors[field]);
            });
            return;
        }
        if(data.data.parentEntityID===null || data.data.parentEntityID===undefined)
            data.data.parentEntityID="";
        if (!data.data.partnershipEndDate || isNaN(Date.parse(data.data.partnershipEndDate))) {
            data.data.partnershipEndDate = ""; // Set to empty string if not a valid date
        }
        if(data.data.unitsClass===null || data.data.unitsClass===undefined)
            data.data.unitsClass="";
        const result = await updateData(clientId,
            data.data,'id')
        if (result.success) {
            console.log('Data updated successfully:', result.data);
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to update data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while updating the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    };
    const onDataGridAdd = async (rowData: any) => {
        rowData.data.clientID = clientId;
        delete rowData.data.id;
        const errors = validateAddress(rowData.data.address);
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach(field => {
                toast.error(errors[field]);
            });
            return;
        }

        if(rowData.data.parentEntityID===null || rowData.data.parentEntityID===undefined)
            rowData.data.parentEntityID="";
        if (!rowData.data.partnershipEndDate || isNaN(Date.parse(rowData.data.partnershipEndDate))) {
            rowData.data.partnershipEndDate = ""; // Set to empty string if not a valid date
        }
        if(rowData.data.unitsClass===null || rowData.data.unitsClass===undefined)
            rowData.data.unitsClass="";
        const result = await addData(rowData.data);

        if (result.success) {
            console.log('Data added successfully:', result.data);
            toast.success("Data added successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to add data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while adding the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    };

    const hasTemplate = (props: any) => {
        return (<div><ButtonComponent cssClass='e-link' onClick={() => handleIDSelection(props)}>Partners</ButtonComponent></div>);
    }

    const handleIDSelection = function (props: any) {
        console.log("partnership id: ", props.partnershipID)
        setPartnershipId(props.partnershipID);
        //initiateNavigation();
    }

    const handleRowClick = function (props: any) {
        console.log("handleRowClick: ", props);
        setPartnershipId(props.rowData.partnershipID);
        setSelectedPartnership(props.rowData);
        console.log("Partnership Page - selected Partnership: ", selectedPartnership);
        initiateNavigation(props.rowData);
    }

    const wait = (n) => new Promise((resolve) => setTimeout(resolve, n));
    const initiateNavigation = async (data) => {
        await wait(100);
        navigate('/partnershiphome', {state: {key: data} });
    }

    const fields = { text: 'clientName', value: 'clientID' };
    const onClientChange =(args:any)=>{
        setClientId(args.itemData.clientID);
    }

    const getBreadcrumbItems  = () => {
        breadcrumbItems.push({title: selectedClient.clientName + " (" + selectedClient.clientID + ")", url: "clienthome"});
        return breadcrumbItems;
    }

    const breadcrumbItems =
    [
        {
            "title": "Home",
            "url": "Clients"
        }
    ]

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader/>}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <Breadcrumb items={getBreadcrumbItems()} title={clientList[0].clientName + " (" + clientId + ")"} url='Clients' breadcrumbItem="Partnerships"/>
                    <div className="col-3">
                        <DropDownListComponent id="atcelement" dataSource={clientList} fields={fields}
                                               allowFiltering={true}
                                               value={clientId} placeholder="Select Client" change={onClientChange}
                                               floatLabelType="Auto"
                        />
                    </div>
                    <br/>
                    <DataGrid id={"gridPartnerships"} columns={partnershipColDef}
                              data={partnershipData} editing={true} add={true} del={true} template={false}
                              onAdd={onDataGridAdd} onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                              hasTemplate={hasTemplate} hasColumnDataSource={true}
                              columnDataSources={partnershipColDataSources}
                              pageType={"Partnership"}  onRowClick={handleRowClick}

                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default Partnerships;
