import React, { useEffect } from "react"
import withRouter from "Components/Common/withRouter";
import {Navigate, useNavigate} from "react-router-dom";

import { logoutUser } from "../../slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { keycloakInstance } from "../../helpers/keycloakHelper";

const Logout = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const selectProperties = createSelector(
    (state: any) => state.Login,
    (isUserLogout) => isUserLogout
  );

  const { isUserLogout } = useSelector(selectProperties);

  const handleLogout = () => {
 
    sessionStorage.removeItem("authUser");
    localStorage.removeItem("authUser");
    sessionStorage.clear();
    localStorage.clear();

  //   if (process.env.REACT_APP_DEFAULTAUTH === "oidc") {
  //  //   if (keycloakInstance.authenticated) {
  //       keycloakInstance.logout({ redirectUri: window.location.origin });
  //       console.log('logged out........................');
  // //    }
  //   }
    
  }
  useEffect(() => {
 
    dispatch(logoutUser(navigate));
    handleLogout();
  }, [dispatch]);

  if (isUserLogout) {
    return <Navigate to="/login" />;
  }

  return <></>;
}


export default withRouter(Logout)
