import allocationGroup from './AllocationGroup.json';
import asset from './Asset.json';
import assetValuation from './AssetValuation.json';
import bigbil from './BIGBIL.json';
import contribution from './Contribution.json';
import events from './Events.json';
import incomeAllocatedResults from './IncomeAllocatedResults.json';
import incomeExpense from './IncomeExpense.json';
import partner from './Partner.json';
import partnerCapitalAccount from './PartnerCapitalAccount.json';
import partnerInterest from './PartnerInterest.json';
import partnership from './Partnership.json';
import redemption from './Redemption.json';
import transfer from './Transfer.json';
import client from './Client.json';
import distributions from "./Distribution.json";
import distributionGroup from "./DistributionGroup.json";
import distributionResults from "./DistributionResults.json";
import calculationResults from "./CalculationResults.json";
import calculationsPartnership from "./CalculationsPartnership.json";
import importResults from "./ImportResutls.json";
import depreciationResults from "./DepreciationResults.json";
import gainAllocations from "./GainAllocation.json";
import gainDisposition from "./GainDisposition.json";

export const allocationGroupColDef = allocationGroup;
export const assetColDef = asset;
export const assetValuationColDef = assetValuation;
export const bigbilColDef = bigbil;
export const contributionColDef = contribution;
export const eventsColDef = events;
export const incomeAllocatedResultsColDef = incomeAllocatedResults;
export const incomeExpenseColDef = incomeExpense;
export const partnerColDef = partner;
export const partnerCapitalAccountColDef = partnerCapitalAccount;
export const partnerInterestColDef = partnerInterest;
export const partnershipColDef = partnership;
export const redemptionColDef = redemption;
export const transferColDef = transfer;
export const clientColDef = client;
export const distributionsColDef = distributions;
export const distributionGroupColDef = distributionGroup;
export const distributionResultsColDef = distributionResults;
export const calculationResultsColDef = calculationResults;
export const calculationsPartnershipColDef = calculationsPartnership;
export const importResultsColDef = importResults;
export const depreciationResultsColDef = depreciationResults;
export const gainAllocationsColDef = gainAllocations;
export const gainDispositionColDef = gainDisposition;

    export type Column = {
    accessor: string;
    Header: string,
    Filter: boolean;
    isDate?: boolean;
    isRequired?: boolean;
    validationRules?: {
        required: boolean;
        number: boolean
    };
    isPrimary?: boolean;
    editType?: 'numericedit' | 'DatepickerEdit' | 'defaultedit' | 'datepickeredit';
}
