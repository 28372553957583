import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import DataGrid from "../../Components/Common/DataGrid";
import {clientColDef} from "../../uihelper/gridutil";
import {fetchData} from '../../BL/Clients';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from "../../hooks/useApi";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../../assets/css/custom.css';
import {validateAddress} from '../../helpers/validations_helper';

const endpoints = {
    getAll: 'api/ClientManagement/Clients',
    getById: 'api/ClientManagement/Clients',
    addData: 'api/ClientManagement/client',
    updateData: 'api/ClientManagement/client',
    deleteData: 'api/ClientManagement/client',
};

const Clients = () => {
    const [clientsData, setClientsData] = useState<any[]>([]);
    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');

    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [clientId, setClientId] = useLocalStorage("id", "");
    const [clientList, setClientList] = useLocalStorage("clients", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");

    const refreshData = () => {
        setLoading(true);
        fetchData(getDataByParams)
            .then(result => {
                setLoading(false);
                if(result.success) {
                    if(result.data && result.data.length > 0) {
                        setClientsData(result.data);
                        const clients = result.data;
                        const list = clients.map(item => {return {
                            clientID: item.clientID,
                            clientName: item.clientID
                        }});
                        setClientList(list);
                        // toast.success("Data refreshed successfully!");
                    } else {
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        refreshData();
    }, []);

    const onDataGridDelete = (rowData:any) => {
        console.log(rowData.data[0])
        console.log(rowData.data[0]['id'])
        deleteData(rowData.data[0]['id'],'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    }
    // update grid data
    const onDataGridUpdate = (rowData:any) => {
        const errors = validateAddress(rowData.data.address);
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach(field => {
                toast.error(errors[field]);
            });
            return;
        }
        updateData(clientId,
            rowData.data,'id').then((response:any) => {
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while updating the data.");
            console.error(error);
            setLoading(false);
        });
    }

    // add data
    const onDataGridAdd = (rowData: any) => {
        console.log("adding: ", rowData.data)
        const errors = validateAddress(rowData.data.address);
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach(field => {
                toast.error(errors[field]);
            });
            return;
        }
        addData(rowData.data).then((response: any) => {
            toast.success("Data added successfully!");
            refreshData();
            setLoading(false);

        }, error => {
            toast.error("An error occurred while adding the data.");
            console.error(error);
            setLoading(false);
        });
    }


    const hasTemplate = (props: any) => {
      return  (<div><ButtonComponent cssClass='e-link' onClick={() => handleIDSelection(props)}>Partnerships</ButtonComponent></div>);
    }

    const handleIDSelection = function (props:any) {
        console.log("client id: ", props.clientID)
        setClientId(props.clientID);
        //initiateNavigation();
    }

    const handleRowClick = function (props: any) {
        console.log("handleRowClick: ", props);
        setClientId(props.rowData.clientID);
        setSelectedClient(props.rowData);
        initiateNavigation(props.rowData);
    }

    const wait = (n) => new Promise((resolve) => setTimeout(resolve, n));
    const initiateNavigation = async (data) => {
        await wait(100);
        navigate('/clienthome');
    }

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader  />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    {/*<Breadcrumb title="" breadcrumbItem="Clients" />*/}
                    <DataGrid  id={"gridPartnerContr"} columns={clientColDef}
                               data={clientsData}
                               editing={true} add={true} del={true} template={false}
                               onAdd={onDataGridAdd} onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                               hasTemplate={hasTemplate} onRowClick={handleRowClick}
                               gridTitle={"Clients"}
                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default Clients;
