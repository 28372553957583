import React, {useState} from 'react';
import axios from "axios";
import {saveAs} from 'file-saver';
import JSZip from 'jszip';
import './columndefgenerator.css';
import {ColumnDirective, ColumnsDirective, GridComponent} from "@syncfusion/ej2-react-grids";
import {random} from "nanoid";


export interface Column {
    accessor: string;
    Header: string;
    Filter: boolean;
    isDate?: boolean;
    isRequired?: boolean;

    validationRules?: {
        required: boolean;
        number: boolean;
        date: boolean;
    };
    isPrimary?: boolean;
    editType?: string;
    visible?: boolean;
    key?: boolean;
    editable: boolean,
    columnType?: string;
    template?:boolean;
    isGrouping?:boolean;
    isAggregated?: boolean;
    requiredValues?:any;
    dependentField?:any;
}

interface SchemaProperty {
    type: string;
    format?: string;
}

interface Schema {
    properties: Record<string, SchemaProperty>;
    required?: string[];
}


function camelCaseToWords(str: string): string {
    return str
        .replace(/ID$/, ' Id') // Handle "ID" at the end of the string
        .replace(/([A-Z])/g, ' $1') // Insert a space before each uppercase letter
        .trim(); // Remove leading or trailing spaces
}

function generateColumnDefs(schema: Schema): Column[] {
    if (!schema.properties) {
        console.error('schema.properties is null or undefined');
        return [];
    }
    //  console.log("Schema is ------------------------- " ,schema)
    const cols: Column[] = [];

    Object.keys(schema.properties).map(key => {
        const property = schema.properties[key];
        const isDate = property.format === 'date-time';
        const isRequired = false;//schema.required && schema.required.includes(key);

        let columnType: string;
        switch (property.type) {
            case 'string':
                columnType = 'text';
                break;
            case 'number':
                columnType = 'numericColumn';
                break;
            case 'boolean':
                columnType = 'boolean';
                break;
            case 'date-time':
                columnType = 'date';
                break;
            default:
                columnType = 'text';
        }
        //    console.warn("-------------------))))) ", columnType)
        let obj: Column = {
            Header: camelCaseToWords(key.charAt(0).toUpperCase() + key.slice(1)),
            accessor: key,
            Filter: false,
            isDate: isDate,
            isRequired: isRequired,
            editType: columnType === 'date' ? 'datepickeredit' : 'defaultedit',
            validationRules: isRequired ? {
                required: true,
                number: columnType === 'numericColumn',
                date: columnType === 'date'
            } : undefined,
            visible: !key.endsWith('InternalID'),
            key: key.endsWith('InternalID'),
            editable: true,
            columnType: columnType
        };

        // console.warn("-------------------*********************** ", obj)
        cols.push(obj);

    })

    return cols;
}

const ColumnDefGenerator: React.FC<{ url: string }> = ({url}) => {
    const [allColumnDefs, setAllColumnDefs] = useState<Record<string, Column[]>>({});
    const [urlInput, setUrl] = useState('');
    const [allSchemas, setAllSchemas] = useState<Record<string, Schema>>({});
    const [gridType, setGridType] = useState('syncfusion');
    const [isGenerationDone, setIsGenerationDone] = useState(false);

    const handleClick = () => {
        axios.get(urlInput)
            .then((response: { components: { schemas: Record<string, Schema>; }; }) => {
                if (response.components && response.components.schemas) {
                    const schemas: Record<string, Schema> = response.components.schemas;
                    setAllSchemas(schemas);
                    const newAllColumnDefs: Record<string, Column[]> = {};


                    for (const schemaName in schemas) {
                        console.error("Schemas is ....", generateColumnDefs(schemas[schemaName]))
                        newAllColumnDefs[schemaName] = generateColumnDefs(schemas[schemaName]);
                        console.warn(newAllColumnDefs[schemaName])
                    }

                    setAllColumnDefs(newAllColumnDefs);
                    // console.warn("All columndefs .....------------------------ ",JSON.stringify(allColumnDefs));
                    setIsGenerationDone(true);
                } else {
                    console.error('Unexpected response structure');
                }
            })
            .catch(err => {
                console.log(err.message);
            });
    };


    const downloadAllJson = () => {
        const zip = new JSZip();

        for (const schemaName in allColumnDefs) {
            const fileName = schemaName.substring(1); // remove the first character
            zip.file(`${fileName}.json`, JSON.stringify(allColumnDefs[schemaName], null, 2));
        }

        zip.generateAsync({type: 'blob'})
            .then(blob => {
                saveAs(blob, 'columnDefs.zip');
            });
    };


    const downloadJson = (interfaceName: string) => {
        console.warn("inside download json ............");
        const data = allColumnDefs[interfaceName];
        if (data) {
            const blob = new Blob([JSON.stringify(data, null, 2)], {type: 'application/json'});
            const fileName = interfaceName.substring(1); // remove the first character
            saveAs(blob, `${fileName}.json`);
        }
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };


    return (
        <div>
            <div className="container">
                <select className="select" value={gridType} onChange={(e) => setGridType(e.target.value)}>
                    <option value="syncfusion">Syncfusion DataGrid</option>
                    <option value="mux">Mux DataGrid</option>
                    <option value="tanstack">Tanstack Table</option>
                    <option value="ag">AG Grid</option>
                </select>
                <input
                    className="input"
                    type="text"
                    value={urlInput}
                    onChange={e => setUrl(e.target.value)}
                />
                <button className="button" onClick={handleClick}>
                    Generate
                </button>
                <button className="button" onClick={downloadAllJson} disabled={!isGenerationDone}>
                    Download All JSON
                </button>
            </div>
            {Object.keys(allColumnDefs).map(schemaName => (

                <div key={schemaName} className="card">
                    <h2>{schemaName}</h2>
                    <div className="button-container">
                        <button className="button"
                                onClick={() => copyToClipboard(JSON.stringify(allColumnDefs[schemaName], null, 2))}>
                            Copy to clipboard
                        </button>
                        <button className="button" onClick={() => downloadJson(schemaName)}>
                            Download JSON
                        </button>
                    </div>
                    <textarea className="textarea" value={JSON.stringify(allColumnDefs[schemaName], null, 2)} readOnly/>
                    <GridComponent id={schemaName + random(10).toString()} dataSource={allColumnDefs[schemaName]}
                                   allowRowDragAndDrop={true}>
                        <ColumnsDirective>
                            {Object.keys(allColumnDefs[schemaName][0]).map((field) => (
                                <ColumnDirective key={field} field={field}
                                                 headerText={field.charAt(0).toUpperCase() + field.slice(1)}/>

                            ))}
                        </ColumnsDirective>
                    </GridComponent>
                </div>


                // <div key={schemaName}>
                //     <h2>{schemaName}</h2>
                //     <textarea
                //         style={{width: '100%', height: '400px'}}
                //         value={JSON.stringify(allColumnDefs[schemaName], null, 2)}
                //         readOnly
                //     />
                //     <button onClick={() => copyToClipboard(JSON.stringify(allColumnDefs[schemaName], null, 2))}>
                //         Copy to clipboard
                //     </button>
                //     <button onClick={() => downloadJson(schemaName)}>Download JSON</button>
                //     {/*<textarea*/}
                //     {/*    style={{ width: '100%', height: '400px' }}*/}
                //     {/*    value={selectedColumnDefs.map(column => `${column.Header}: ${column.accessor}`).join('\n')}*/}
                //     {/*    readOnly*/}
                //     {/*/>*/}
                //     {/*          <h2>{schemaName} TypeScript Interface</h2>*/}
                //     {/*          <textarea*/}
                //     {/*              style={{ width: '100%', height: '400px' }}*/}
                //     {/*              value={generateInterfaceNew(allSchemas[schemaName],schemaName)}*/}
                //     {/*              readOnly*/}
                //     {/*          />*/}
                //     {/*                   <button onClick={() => copyToClipboard(generateInterface(allColumnDefs[schemaName]))}>*/}
                //     {/*  Copy to clipboard*/}
                //     {/*</button>*/}
                //     <br/>
                // </div>
            ))}
        </div>

    );
};
export default ColumnDefGenerator;