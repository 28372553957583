import React, { useEffect, useState } from "react";

// Redux
import { Link,  useNavigate} from "react-router-dom";
import { Row, Col, CardBody, Card, Container, Form, Input, Label, FormFeedback, Alert } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import  "../../assets/scss/datagrid.scss";
//import thunk
import { loginuser, resetLoginMsgFlag, socialLogin } from "slices/auth/login/thunk";

import withRouter from "Components/Common/withRouter";
import { createSelector } from 'reselect';
import BounceLoader from "react-spinners/BounceLoader";
import LoadingOverlay from "react-loading-overlay-ts";


const Login = (props: any) => {
  const [show, setShow] = useState(false);
  const dispatch: any = useDispatch();

  const navigate = useNavigate();
// Inside your component
const authUser = useSelector((state: any) => state.authUser);

const [message, setMessage] = useState('');

// Access the properties of the auth object
//const { profile, token, isAuthenticated, keycloakInitiated } = authUser;
  //meta title
  document.title = "PartnerTec | Partnership Tax Computations";

  useEffect(() => {
    const authUser = sessionStorage.getItem('authUser');

    if (authUser) {
      setMessage('Logging in please wait ...');
    } else {
      setMessage('Preparing to logout ... please wait');
    }
    // console.warn("Auth user ................",authUser);
    // if (authUser) {
    //   navigate('/clients');
    // }
  }, [authUser]);

  const selectProperties = createSelector(
    (state: any) => state.Login,
    (error) => error
  );

  const { error } = useSelector(selectProperties);

  // Form validation 
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "admin@themesbrand.com" || '',
      password: "123456" || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values: any) => {
      dispatch(loginuser(values, props.router.navigate));
    }
  });

  const signIn = (type: any) => {
    dispatch(socialLogin(type, props.router.navigate))
  }

  const socialResponse = (type: any) => {
    signIn(type)
  }

  useEffect(() => {
    if (error && (process.env.REACT_APP_DEFAULTAUTH !== 'oidc')) {
      setTimeout(() => {
        dispatch(resetLoginMsgFlag())
      }, 3000);
    }
  }, [dispatch, error])



  useEffect(() => {
    if (process.env.REACT_APP_DEFAULTAUTH === 'oidc') {
      dispatch(loginuser("",navigate));
    }
  }, []);


  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        {process.env.REACT_APP_DEFAULTAUTH=== 'oidc' ?
  
            <div className="loadingDiv" >
              <LoadingOverlay
                  active={true}
                  spinner={<BounceLoader  />}
                  text='.....'
              />
            {/* <p>{message}</p> */}
          </div>
          
            :
            <Container>

              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col className="col-7">
                          <div className="text-primary p-4">
                            <h5 className="text-primary">Welcome Back !</h5>
                            <p>Sign in to continue to Partnertec Tax Management Application.</p>
                          </div>
                        </Col>
                        <Col className="col-5 align-self-end">
                          <img src={profile} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div className="auth-logo">
                        <Link to="/" className="auth-logo-light">
                          <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                          />
                        </span>
                          </div>
                        </Link>
                        <Link to="/" className="auth-logo-dark">
                          <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                          />
                        </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        <Form className="form-horizontal"
                              onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                        >
                          <div className="mb-3">
                            {error ? <Alert color="danger">{error}</Alert> : null}
                            <Label className="form-label">Email</Label>
                            <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email && validation.errors.email ? true : false
                                }
                            />
                            {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                  name="password"
                                  value={validation.values.password || ""}
                                  type={show ? "text" : "password"}
                                  placeholder="Enter Password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.password && validation.errors.password ? true : false
                                  }
                              />
                              <button onClick={() => setShow(!show)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>
                            </div>
                            {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                                className="btn btn-primary btn-block "
                                type="submit"
                            >
                              Log In
                            </button>
                          </div>

                          <div className="mt-4 text-center">
                            <h5 className="font-size-14 mb-3">Sign in with</h5>

                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <Link
                                    to="#"
                                    className="social-list-item bg-primary text-white border-primary"
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      socialResponse("facebook")
                                    }}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              </li>{" "}
                              <li className="list-inline-item">
                                <Link
                                    to="#"
                                    className="social-list-item bg-info text-white border-info"
                                >
                                  <i className="mdi mdi-twitter" />
                                </Link>
                              </li>{" "}
                              <li className="list-inline-item">
                                <Link
                                    to="#"
                                    className="social-list-item bg-danger text-white border-danger"
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      socialResponse("google")
                                    }}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              </li>
                            </ul>
                          </div>

                          <div className="mt-4 text-center">
                            <Link to="/forgot-password" className="text-muted">
                              <i className="mdi mdi-lock me-1" /> Forgot your
                              password?
                            </Link>
                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p>
                      Don&apos;t have an account ?{" "}
                      <Link
                          to="/register"
                          className="fw-medium text-primary"
                      >
                        {" "}
                        Signup now{" "}
                      </Link>{" "}
                    </p>
                    <p>
                      © {new Date().getFullYear()} Skote. Crafted with{" "}
                      <i className="mdi mdi-heart text-danger" /> by Themesbrand
                    </p>
                  </div>
                </Col>
              </Row>

            </Container>
        }

      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
