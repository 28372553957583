import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {
    Aggregate,
    AggregateColumnDirective,
    AggregateColumnsDirective,
    AggregateDirective,
    AggregatesDirective,
    ColumnDirective,
    ColumnsDirective,
    ExcelExport,
    GridComponent,
    Group,
    Inject,
    Page,
    Resize,
    Search,
    Sort,
    Toolbar
} from "@syncfusion/ej2-react-grids";
import '../../assets/scss/datagrid.scss';

//custom generator
import {Column} from "../generator/ColumnDefGeenrator";

interface DataViewGridProps {
    dataloader?: (loaded: boolean) => void;
    id: string;
    data: any[];
    columns: any;
    enableSignalR?: boolean;
    hasMultiSelect?: boolean;
    selectedRows?: any[];
    setSelectedRows?: (args: any) => void;
    refreshGrid?: (doRefresh: boolean) => void;
    hasExport?: boolean;
    gridTitle?: string;
    hasGrouping?: boolean;

}


const DataViewGrid = forwardRef(({
                                     id,
                                     data,
                                     columns,
                                     enableSignalR = false,
                                     hasMultiSelect = false,
                                     setSelectedRows = (args: any) => {
                                     },
                                     hasExport = false,
                                     gridTitle,
                                     hasGrouping = false

                                 }: DataViewGridProps, ref) => {

    // const gridRef:any = React.useRef<GridComponent>(null);

    const [griddata, setGridData] = React.useState<any>([])
    const signalREndpoint: any = process.env.REACT_APP_SIGNALR_ENDPOINT;
    let gridInstance: any;
    const [rowsSelected, setRowsSelected] = useState<any>([]);

    React.useEffect(() => {
        // console.log('Data prop changed:', data);
        if (gridInstance) {
            (gridInstance as GridComponent).dataSource = data;
            (gridInstance as GridComponent).dataBind();
        }

        // console.warn("Does the grid have grouping ?????????????????????????????? ", hasGrouping);
    }, [data, gridInstance]);

    useImperativeHandle(ref, () => ({
        refreshData: (newData) => {
            // Implement your data refreshing logic here
            console.warn("Refresh   data is called ...", newData);
            (gridInstance as GridComponent).changeDataSource(newData);
            //      (gridInstance as GridComponent).dataBind();
            //    (gridInstance as GridComponent).refresh();
        }
    }));

    const footTemplate = (props) => {
        return (
            <span style={{ fontWeight: 'bold',textAlign: 'left'}}>
                Sum: {props.Sum}
            </span>
        );
    };
// Function that returns a React functional component using the title prop
    const createToolbarTemplate = (title?: string) => {
        return () => (
            title ? (
                <div style={{fontWeight: 'bold', marginRight: '50px', fontSize: 'larger'}}>
                    {title}
                </div>
            ) : <div style={{display: 'none', width: '0px'}}></div>  // Return an empty div if title is null or undefined
        );
    };


    // This method is used to make searching on keystroke
    const created = () => {
        if (gridInstance) {
            if (hasGrouping) {
              let aggregateColumns:any = [];
          
              columns.forEach((col) => {
                if (col.isAggregated && hasGrouping) {
                  // Add the aggregate column.
                  let aggregateColumn = { 
                    type: 'Sum', 
                    field: col.accessor, 
                    groupFooterTemplate: footTemplate
                  };
          
                  aggregateColumns.push(aggregateColumn);
                }
              });
          
              (gridInstance as GridComponent).aggregates = [{
                columns: aggregateColumns
              }];
            }
            // Rest of your code...
          }
    };


    const rowSelected = (args: any) => {
        const selectedRecords = gridInstance.getSelectedRecords();

        setRowsSelected(selectedRecords);
        setSelectedRows(selectedRecords);
        console.log("Selected records are ...", selectedRecords);
    };
    const ToolbarTemplate = createToolbarTemplate(gridTitle);


    let toolbarOptions: (string | {
        template: () => JSX.Element,
        align: 'Left' | 'Right' | 'Center'
    })[] = [
        {template: ToolbarTemplate, align: 'Left'}, // Custom template with title
        'Search', // Other predefined toolbar items
    ];
    const pageSettings = {pageSize: 20, pageSizes: true};


    if (hasExport) {
        toolbarOptions.push('ExcelExport');
    }

    const handleToolbarClick = ({item}) => {

        if (item.properties.id === `${id}_excelexport`) {
            console.log("Insided exceel export ....................");
            (gridInstance as GridComponent)?.excelExport();
        }
    };

    const headerTemplate = () => {
        return (<div></div>);
    }
    const customDateFormat = (col, value) => {
        // console.log("Custom date format value is ", value);
        let val = value[col.accessor];
        if (typeof val !== 'string') return "";
        // Truncate the fractional seconds part
        const truncatedValue = val.split('.')[0];
        const date = new Date(truncatedValue);
        let datevalue:any;
        if(col.isDate){
                datevalue = date.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'

            });
        
        }
        // if(col.accessor === 'interestDate' || col.accessor === 'transactionDate' || col.accessor === 'eventDate' || col.accessor === 'effectiveDate'
        //     || col.accessor === 'dispositionDate' || col.accessor === 'originalPlacedInServiceDate'
        //     || col.accessor === 'effectivePlacedInServiceDate')
        // {
        //     datevalue = date.toLocaleString('en-US', {
        //         year: 'numeric',
        //         month: '2-digit',
        //         day: '2-digit'

        //     });
        // }
        // else{
        //     datevalue = date.toLocaleString('en-US', {
        //         year: 'numeric',
        //         month: '2-digit',
        //         day: '2-digit',
        //         hour: '2-digit',
        //         minute: '2-digit',
        //         second: '2-digit',
        //         hour12: true
        //     });
        // }

        return datevalue;
    };

    const groupColumns = columns.filter(col => col.isGrouping).map(col => col.accessor);
    // console.warn("Group columns??????????????????,", groupColumns);
    const groupOptions = {
        columns: groupColumns,
        showDropArea: false,
        disablePageWiseAggregates: true
    };

    // let aggregates = columns.filter(col => col.isAggregated).map(col => {
    //     {type:'Sum', field:col.accessor,}
    // })

    return (
        <GridComponent ref={grid => gridInstance = grid}
                       id={id}
                       pageSettings={pageSettings}
                       allowTextWrap={true}
                       textWrapSettings={{wrapMode: 'Header'}}
                       dataSource={data}
                       autoFit={false}
                       allowPaging={true}
                       gridLines='Both'
                       toolbar={toolbarOptions}
                       toolbarClick={handleToolbarClick}
                       selectionSettings={hasMultiSelect ? {type: 'Multiple'} : {}}
                       allowResizing={false}
                       allowSorting={true}
                       enableImmutableMode={false}
                       created={created.bind(this)}
                       allowSelection={hasMultiSelect}
                       rowSelected={rowSelected.bind(this)}
                       allowExcelExport={true}
                       groupSettings={hasGrouping ? groupOptions : {}}
                       allowGrouping={hasGrouping}
                     
        >
            <ColumnsDirective>
                {hasMultiSelect && <ColumnDirective type='checkbox' width='50'/>}
                {columns.map((col: Column, index: React.Key | null | undefined) => (
                   <ColumnDirective key={index} field={col.accessor}
                   headerTextAlign={'Center'}
                   headerText={col.Header}
                   textAlign="Right"
                   autoFit={false}
                   width={150}
                   visible={col.visible}
                   isPrimaryKey={col.key}
                   allowEditing={false}
                   template={col.columnType === 'date-time' ? (rowData) => customDateFormat(col, rowData) : undefined}
  >

  </ColumnDirective>
                ))}
            </ColumnsDirective>
            <Inject
                services={hasGrouping ? [Sort, Toolbar, Resize, Page, Search, ExcelExport, Aggregate, Group] : [Sort, Toolbar, Resize, Page, Search, ExcelExport]}/>

        </GridComponent>


    );
});

export default DataViewGrid;