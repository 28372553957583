import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../Components/Common/Breadcrumb";
import DataGrid from 'Components/Common/DataGrid';
import {partnerColDef} from '../../uihelper/gridutil';
import useApi from "../../hooks/useApi";
import {fetchData} from "../../BL/Partners";
import {toast, ToastContainer} from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {partnerColDataSources} from "../../uihelper/columndatasources";
import {useNavigate} from "react-router-dom";
import {validateAddress} from '../../helpers/validations_helper';

const endpoints = {
    getAll: 'Partners',
    getById: 'Partners',
    addData: 'Partners',
    updateData: 'Partners',
    deleteData: 'Partners',
};

const Partners = () => {
    const [partnersData, setPartnersData] = useState<any[]>([]);
    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);
    const [clientId, setClientId] = useLocalStorage("id", "");
    const [partnershipId, setPartnershipId] = useLocalStorage("pid", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");
    const [selectedPartnership, setSelectedPartnership] = useLocalStorage("selectedPartnership", "");
    const [partnerId, setPartnerId] = useLocalStorage("prid", "");
    const [selectedPartner, setSelectedPartner] = useLocalStorage("selectedPartner", "");
    const navigate = useNavigate();

    const refreshData = () => {
        setLoading(true);
        fetchData(getDataByParams, partnershipId, clientId)
            .then(result => {
                setLoading(false);
                if(result.success) {
                    if(result.data.length > 0) {
                        setPartnersData(result.data);
                        toast.success("Data refreshed successfully!");
                    } else {
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        refreshData();
    }, []);

    const onDataGridDelete = (data:any) => {
        deleteData(data?.data[0]?.id,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    };

    // update data
    const onDataGridUpdate = async (data:any) => {
        const errors = validateAddress(data.data.address);
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach(field => {
                toast.error(errors[field]);
            });
            return;
        }
        if(data.data.isTieringPartner===null || data.data.isTieringPartner===undefined)
            data.data.isTieringPartner="";
        if(data.data.parentPartnerID===null || data.data.parentPartnerID===undefined)
            data.data.parentPartnerID="";
        const result = await updateData(clientId,
            data.data,'id')
        if (result.success) {
            console.log('Data updated successfully:', result.data);
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to update data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while updating the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    };
    const onDataGridAdd = async (data: any) => {
        data.data.clientID = clientId;
        data.data.partnershipID = partnershipId;

        delete data.data.id;
        const errors = validateAddress(data.data.address);
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach(field => {
                toast.error(errors[field]);
            });
            return;
        }
        if(data.data.isTieringPartner===null || data.data.isTieringPartner===undefined)
            data.data.isTieringPartner="";
        if(data.data.parentPartnerID===null || data.data.parentPartnerID===undefined)
            data.data.parentPartnerID="";
        const result = await addData(data.data);

        if (result.success) {
            console.log('Data added successfully:', result.data);
            toast.success("Data added successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to add data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while adding the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    };
    const handleRowClick = function (props: any) {
        console.log("handleRowClick: ", props);
        setPartnerId(props.rowData.partnerID);
        setSelectedPartner(props.rowData);
        console.log("Partner Page - selected Partner: ", selectedPartner);
        initiateNavigation(props.rowData);
    }

    const wait = (n) => new Promise((resolve) => setTimeout(resolve, n));
    const initiateNavigation = async (data) => {
        await wait(100);
        navigate('/partnerhome', {state: {key: data} });
    }
    const getBreadcrumbItems  = () => {
        breadcrumbItems.push({title: selectedClient.clientName + " (" + selectedClient.clientID + ")", url: "clienthome"});
        breadcrumbItems.push({title: selectedPartnership.partnershipName + " (" + selectedPartnership.partnershipID + ")", url: "partnershiphome"});
        return breadcrumbItems;
    }

    const breadcrumbItems =
        [
            {
                "title": "Home",
                "url": "Clients"
            }
        ]

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader  />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <Breadcrumb items={getBreadcrumbItems()} title={clientId+" /" + partnershipId} breadcrumbItem="Partners" />
                    <DataGrid  id={"gridPartner"} columns={partnerColDef}
                               data={partnersData} editing={true} add={true} del={true} onAdd={onDataGridAdd}
                               onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                               hasColumnDataSource={true} columnDataSources={partnerColDataSources}
                               pageType={"Partners"}
                               onRowClick={handleRowClick}
                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}
export default Partners;
