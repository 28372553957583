// @ts-ignore
import Keycloak from 'keycloak-js';

export const keycloakConfig: any ={
    url: process.env.REACT_APP_KEYCLOAK_INSTANCE_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: 'taxtechClient',
    credentials: {
      secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET
    }
  
  };
  export const keycloakInstance = new Keycloak(keycloakConfig);
  

