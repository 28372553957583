import React, { useEffect, useRef, useCallback, useState } from "react";
//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";

import { Link, useLocation, useNavigate } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import {useLocalStorage} from "../../hooks/useLocalStorage";

const SidebarContent = (props: any) => {
  const ref = useRef<any>();
  const [partnershipID, setPartnershipID] = useLocalStorage("pid", "");
  const location = useLocation();
  const [isPartnershipsOpen, setIsPartnershipsOpen] = useState(false);
  const navigate = useNavigate();
  const [partnerID, setPartnerID] = useLocalStorage("prid", "");
  const [isPartnersOpen, setIsPartnersOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === "/partnershiphome") {
      const params = new URLSearchParams(location.search);
      const pid = params.get("partnershipId");
      if (pid) {
        setPartnershipID(pid);
      }
      setIsPartnershipsOpen(true);
    }
  }, [location.pathname, partnershipID]);

 /* useEffect(() => {
    console.log("isPartnershipsOpen:", isPartnershipsOpen);
  }, [isPartnershipsOpen]);*/

  useEffect(() => {
    if (location.pathname === "/partnerhome") {
      const params = new URLSearchParams(location.search);
      const prid = params.get("partnerId");
      if (prid) {
        setPartnerID(prid);
      }
      setIsPartnersOpen(true);
    }
  }, [location.pathname, partnerID]);
  const handlePartnershipsTextClick = () => {
    console.log("handlePartnershipsTextClick");
    navigate("/partnerships");
  };

  const handlePartnershipsArrowClick = (e) => {
    e.preventDefault();
    setIsPartnershipsOpen(!isPartnershipsOpen);
  };


  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Home")}</span>
              </Link>
              <ul className="sub-menu">
                {/*<li>
                  <Link to="/dashboard">{props.t("Dashboard & Activity")}</Link>
                </li>*/}
                <li>
                  <Link to="/clients">{props.t("Clients")}</Link>
                </li>
                  <li className={isPartnershipsOpen ? "mm-active" : ""}>
                    <Link to="/partnerships" onClick={handlePartnershipsTextClick} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginRight: '36px' }}>{props.t("Partnerships")}</span>
                      <i
                          className={isPartnershipsOpen ? "bx bx-chevron-up" : "bx bx-chevron-down"}
                          onClick={handlePartnershipsArrowClick}
                          style={{ fontSize: '16px', lineHeight: '16px', marginLeft: '36px' }}
                      ></i>
                    </Link>
                    {isPartnershipsOpen && partnershipID !== undefined && partnershipID !== "" &&
                        <ul className="sub-menu">
                          <li>
                            <Link to="/partners">{props.t("Partners")}</Link>
                          </li>
                          <li>
                            <Link to="/contributions">{props.t("Contributions")}</Link>
                          </li>
                          <li>
                            <Link to="/transfers">{props.t("Transfers")}</Link>
                          </li>
                          <li>
                            <Link to="/redemptions">{props.t("Redemptions")}</Link>
                          </li>
                          <li>
                            <Link to="/distributions">{props.t("Distributions")}</Link>
                          </li>
                          <li>
                            <Link to="/distributiongroup">{props.t("DistributionGroup")}</Link>
                          </li>
                          {/*<li>
                      <Link to="/dashboard">{props.t("Asset Acquisition")}</Link>
                    </li>
                    <li>
                      <Link to="/dashboard">{props.t("Asset Disposition")}</Link>
                    </li>
                    <li>
                      <Link to="/dashboard">{props.t("Revaluations")}</Link>
                    </li>*/}
                          <li>
                            <Link to="/assets">{props.t("Assets")}</Link>
                          </li>
                          <li>
                            <Link to="/income-expense">{props.t("Income/Expense")}</Link>
                          </li>
                          <li>
                            <Link to="/allocations">{props.t("Allocation Groups")}</Link>
                          </li>
                        </ul>}
                  </li>
                {/*<li className={isPartnersOpen ? "mm-active" : ""}>
                  <Link to="/partners">{props.t("Partners")}</Link>
                </li>*/}
                  <li>
                    <Link to="/imports">{props.t("Imports")}</Link>
                  </li>
                  <li>
                    <Link to="/calculations">{props.t("Calculations")}</Link>
                  </li>
                  <li>
                    <Link to="/results">{props.t("Calculation Results")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Reports")}</Link>
                  </li>
              </ul>
            </li>

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
);
};
export default withRouter(withTranslation()(SidebarContent));
