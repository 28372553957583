
export default class DataHelper {


    private importResults: any[] = [
        {
            transactionType: "Contributions",
            totalRecords: "10",
            acceptedRecords: "8",
            rejectedRecords: "2",
        },
        {
            transactionType: "Redemptions",
            totalRecords: "10",
            acceptedRecords: "8",
            rejectedRecords: "2",
        },
        {
            transactionType: "Transfers",
            totalRecords: "10",
            acceptedRecords: "8",
            rejectedRecords: "2",
        },
        {
            transactionType: "Transfers",
            totalRecords: "10",
            acceptedRecords: "8",
            rejectedRecords: "2",
        }

    ];
    public getImportResults(): any[] {
        return this.importResults;
    }

    private partnershipData: any[] = [
        {
            id: 1,
            partnershipID: "PS1",
            partnershipName: "PS1",
            isSubEntity: "Yes",
            subEntityID: "SE1",
            streetAddress: "Houston, TX",
            city: "Mireyaside",
            state: "PA",
            postalCode: 80172,
            tin: "TIN 1",
            irsCenter: "Texas",
            isPTP: "Yes",
            typeOfAssignment: "A",
            partnershipStartDate: "11-11-2022",
            election754: "No",
            partner: "Partners"
        },
        {
            id: 2,
            partnershipID: "PS2",
            partnershipName: "PS2",
            isSubEntity: "Yes",
            subEntityID: "SE2",
            streetAddress: "123 Some Drive",
            city: "San Jose",
            state: "CA",
            postalCode: 80172,
            tin: "TIN 1",
            irsCenter: "California",
            isPTP: "No",
            typeOfAssignment: "A",
            partnershipStartDate: "12-11-2022",
            election754: "Yes",
            partner: "Partners"
        },
        {
            id: 3,
            partnershipID: "PS3",
            partnershipName: "PS3",
            isSubEntity: "Yes",
            subEntityID: "SE3",
            streetAddress: "Some Street",
            city: "Mireyaside",
            state: "PA",
            postalCode: 80172,
            tin: "TIN 3",
            irsCenter: "Texas",
            isPTP: "No",
            typeOfAssignment: "A",
            partnershipStartDate: "13-11-2022",
            election754: "Yes",
            partner: "Partners"
        },
        {
            id: 4,
            partnershipID: "PS4",
            partnershipName: "PS4",
            isSubEntity: "Yes",
            subEntityID: "SE4",
            streetAddress: "Houston, TX",
            city: "Mireyaside",
            state: "PA",
            postalCode: 80172,
            tin: "TIN 1",
            irsCenter: "Texas",
            isPTP: "No",
            typeOfAssignment: "A",
            partnershipStartDate: "14-11-2022",
            election754: "Yes",
            partner: "Partners"
        },
    ]
    public getPartnerShips(): any[] {
        return this.partnershipData;
    }

    private partnerData: any[] =
    [
        {
            id: "1",
            partnerID: "e3c071ad-53fe-4601-b168-a08bca67fcf7",
            partnershipID: "ef8d243d-376a-4577-bdb3-aba6700ffc50",
            partnerName: "Bogisich Group",
            partnerTIN: "979243082",
            partnerType: "General",
            partnerTaxType: "Company",
            isForeign: false,
            streetAddress: "351 S Monroe Street",
            city: "Mireyaside",
            state: "PA",
            postalCode: "80172",
            stateOfResidency: "Kentucky",
            isDRE: true,
            nameOfOwner: "Raquel Lindgren",
            tinOfOwner: "3132319",
            parentPartnerID: "8d035028-7d9d-49fd-b934-2a34244a4091",
            gpLp: "LP",
            ScheduleK1: "ScheduleK1"
        },
{
    id: "2",
    partnerID: "e3c071ad-53fe-4601-b168-a08bca67fcf7",
    partnershipID: "ef8d243d-376a-4577-bdb3-aba6700ffc50",
    partnerName: "Bogisich Group",
    partnerTIN: "979243082",
    partnerType: "General",
    partnerTaxType: "Company",
    isForeign: false,
    streetAddress: "351 S Monroe Street",
    city: "Mireyaside",
    state: "PA",
    postalCode: "80172",
    stateOfResidency: "Kentucky",
    isDRE: true,
    nameOfOwner: "Raquel Lindgren",
    tinOfOwner: "3132319",
    parentPartnerID: "8d035028-7d9d-49fd-b934-2a34244a4091",
    gpLp: "LP",
    ScheduleK1: "ScheduleK1"
},
{
    id: "3",
    partnerID: "e3c071ad-53fe-4601-b168-a08bca67fcf7",
    partnershipID: "ef8d243d-376a-4577-bdb3-aba6700ffc50",
    partnerName: "Bogisich Group",
    partnerTIN: "979243082",
    partnerType: "General",
    partnerTaxType: "Company",
    isForeign: false,
    streetAddress: "351 S Monroe Street",
    city: "Mireyaside",
    state: "PA",
    postalCode: "80172",
    stateOfResidency: "Kentucky",
    isDRE: true,
    nameOfOwner: "Raquel Lindgren",
    tinOfOwner: "3132319",
    parentPartnerID: "8d035028-7d9d-49fd-b934-2a34244a4091",
    gpLp: "LP",
    ScheduleK1: "ScheduleK1"
},
{
    id: "4",
    partnerID: "e3c071ad-53fe-4601-b168-a08bca67fcf7",
    partnershipID: "ef8d243d-376a-4577-bdb3-aba6700ffc50",
    partnerName: "Bogisich Group",
    partnerTIN: "979243082",
    partnerType: "General",
    partnerTaxType: "Company",
    isForeign: false,
    streetAddress: "351 S Monroe Street",
    city: "Mireyaside",
    state: "PA",
    postalCode: "80172",
    stateOfResidency: "Kentucky",
    isDRE: true,
    nameOfOwner: "Raquel Lindgren",
    tinOfOwner: "3132319",
    parentPartnerID: "8d035028-7d9d-49fd-b934-2a34244a4091",
    gpLp: "LP",
    ScheduleK1: "ScheduleK1"
},
{
    id: "5",
    partnerID: "e3c071ad-53fe-4601-b168-a08bca67fcf7",
    partnershipID: "ef8d243d-376a-4577-bdb3-aba6700ffc50",
    partnerName: "Bogisich Group",
    partnerTIN: "979243082",
    partnerType: "General",
    partnerTaxType: "Company",
    isForeign: false,
    streetAddress: "351 S Monroe Street",
    city: "Mireyaside",
    state: "PA",
    postalCode: "80172",
    stateOfResidency: "Kentucky",
    isDRE: true,
    nameOfOwner: "Raquel Lindgren",
    tinOfOwner: "3132319",
    parentPartnerID: "8d035028-7d9d-49fd-b934-2a34244a4091",
    gpLp: "LP",
    ScheduleK1: "ScheduleK1"
},
]
    public getClientPartnerData(): any[] {
        return this.partnerData;
    }

    private Contributions: any[] = [
        {
            id: 1,
            transactionId:"ConTan1",
            partnerId:"Partner1",
            transactionType:"contribution",
            transactionDate:"08-08-2023",
            transactionOrder:"1",
            contributionType:"cash",
            amount:"10,000",
            assetId:"Asset2",
            taxBasisAtContribution:"10,000",
            basis704:"10,000",
            percentAssigned:"50%",
            unitsAssigned:"2",
            depreciation:"3000",
        },
        {
            id: 2,
            transactionId:"ConTan2",
            partnerId:"Partner2",
            transactionType:"Contribution",
            transactionDate:"09-08-2023",
            transactionOrder:"2",
            contributionType:"Asset",
            amount:"10,000",
            assetId:"Asset1",
            taxBasisAtContribution:"6,000",
            basis704:"10,000",
            percentAssigned:"50%",
            unitsAssigned:"2",
            depreciation:"4000",
        },
        {
            id: 3,
            transactionId:"ConTan3",
            partnerId:"Partner3",
            transactionType:"Contribution",
            transactionDate:"10-08-2023",
            transactionOrder:"3",
            contributionType:"cash",
            amount:"10,000",
            assetId:"Asset2",
            taxBasisAtContribution:"10,000",
            basis704:"10,000",
            percentAssigned:"50%",
            unitsAssigned:"2",
            depreciation:"6000",
        },
        {
            id: 4,
            transactionId:"ConTan4",
            partnerId:"Partner4",
            transactionType:"Contribution",
            transactionDate:"11-08-2023",
            transactionOrder:"4",
            contributionType:"Asset",
            amount:"10,000",
            assetId:"Asset2",
            taxBasisAtContribution:"6,000",
            basis704:"10,000",
            percentAssigned:"50%",
            unitsAssigned:"2",
            depreciation:"5000",
        }
    ]

    public getContributions(): any[] {
        return this.Contributions;
    }

    private Transfers: any[] = [
        {
            id: 1,
            transactionId: "TR990",
            transactionType: "Transfer",
            transactionDate: "12-08-2022",
            transactionOrder: "1",
            transferorPartnerId: "Partner1",
            transfereePartnerId: "Partner2",
            liabilityAssumed: "5000",
            purchaseAmount: "5000",
            transferredInterestDate: "06-14-2022",
            transferInterestId: "Int123",
            transferLot: "FIFO",
            transferFraction: "20",
        },
        {
            id: 2,
            transactionId: "TR991",
            transactionType:"Transfer",
            transactionDate:"13-08-2022",
            transactionOrder	:"2",
            transferorPartnerId:"Partner1",
            transfereePartnerId	:"Partner2",
            liabilityAssumed	:"5000",
            purchaseAmount:"500",
            transferredInterestDate:"06-14-2022",
            transferInterestId	:"Int123",
            transferLot:"LIFO",
            transferFraction:"10",
        },
        {
            id: 3,
            transactionId:"TR992",
            transactionType:"Transfer",
            transactionDate:"14-08-2022",
            transactionOrder	:"3",
            transferorPartnerId:"Partner1",
            transfereePartnerId	:"Partner2",
            liabilityAssumed	:"5000",
            purchaseAmount:"500",
            transferredInterestDate:"06-14-2022",
            transferInterestId	:"Int123",
            transferLot:"LIFO",
            transferFraction:"20",
        },
        {
            id: 4,
            transactionId:"TR993",
            transactionType:"Transfer",
            transactionDate:"15-08-2022",
            transactionOrder	:"4",
            transferorPartnerId:"Partner1",
            transfereePartnerId	:"Partner2",
            liabilityAssumed	:"5000",
            purchaseAmount:"5000",
            transferredInterestDate:"06-14-2022",
            transferInterestId	:"Int123",
            transferLot:"FIFO",
            transferFraction:"10",
        },
    ]
    public getTransfers(): any[] {
        return this.Transfers;
    }
    private Redemptions: any[] = [
        {
            id: 1,
            transactionId:" Red123",
            transactionDate:"12-31-2022 ",
            transactionType:"Redemption",
            transactionOrder:"1",
            partnerId:"Partner1",
            redemptionFraction:"20",
            amountReceived:"75000",
        },
{
    id: 2,
    transactionId:" Red122",
    transactionDate:"13-31-2022 ",
    transactionType:"Redemption",
    transactionOrder:"2",
    partnerId:"Partner2",
    redemptionFraction:"70",
    amountReceived:"75000",
},
{
    id: 3,
    transactionId:" Red124",
    transactionDate:"14-31-2022 ",
    transactionType:"Redemption",
    transactionOrder:"3",
    partnerId:"Partner3",
    redemptionFraction:"30",
    amountReceived:"75000",
},
{
    id: 4,
    transactionId:" Red125",
    transactionDate:"15-31-2022 ",
    transactionType:"Redemption",
    transactionOrder:"4",
    partnerId:"Partner4 ",
    redemptionFraction:"40",
    amountReceived:"75000",
},
]
    public getRedemptions(): any[] {
        return this.Redemptions;
    }
    private AllocationsGroup: any[] = [
        {
            id: 1,
            partnershipId: "Ps1",
            allocationGroupId: "test1",
            partnerId: "Partner1",
            allocation: "100",
            allocationAmount: "200",
        },
        {
            id: 2,
            partnershipId: "Ps2",
            allocationGroupId: "test1",
            partnerId: "Partner2",
            allocation: "100",
            allocationAmount: "400",
        },
        {
            id:3,
            partnershipId: "Ps3",
            allocationGroupId: "test1",
            partnerId: "Partner3",
            allocation: "100",
            allocationAmount: "600",
        },
        {
            id: 4,
            partnershipId: "Ps4",
            allocationGroupId: "test1",
            partnerId: "Partner4",
            allocation: "100",
            allocationAmount: "700",
        },
    ]
    public getAllocationGroups(): any[] {
        return this.AllocationsGroup;
    }
    private IncomeExpences: any[] = [
{
    id: 1,
    partnershipId : "P1",
    subEntityId : "SE1",
    incomeExpenseItemId : "IE1",
    incomeExpenseDescription : "Consulting Fee",
    accountId : "AC001",
    amount : "$5000",
    taxYearId : "TY2023",
    allocationMethod : "Pro Rata",
    allocationGroupId : "AG001",
    taxPeriodId : "TP202311"
},
{
    id: 2,
    partnershipId : "P2",
    subEntityId : "SE2",
    incomeExpenseItemId : "IE2",
    incomeExpenseDescription : "exam fee",
    accountId : "AC002",
    amount : "$6000",
    taxYearId : "TY2023",
    allocationMethod : "Pro Rata",
    allocationGroupId : "AG002",
    taxPeriodId : "TP202312"
},
{
    id: 3,
    partnershipId : "P3",
    subEntityId : "SE3",
    incomeExpenseItemId : "IE3",
    incomeExpenseDescription : "supply fee",
    accountId : "AC003",
    amount : "$7000",
    taxYearId : "TY2023",
    allocationMethod : "Pro Rata",
    allocationGroupId : "AG003",
    taxPeriodId : "TP202313"
},
{
    id: 4,
    partnershipId : "P4",
    subEntityId : "SE4",
    incomeExpenseItemId : "IE4",
    incomeExpenseDescription : "common fee",
    accountId : "AC004",
    amount : "$80000",
    taxYearId : "TY2023",
    allocationMethod : "Pro Rata",
    allocationGroupId : "AG004",
    taxPeriodId : "TP202314"
}
    ]
    public getIncomeExpences(): any[] {
        return this.IncomeExpences;
    }

    private static CalculationData: any[] = [
        {
            id: "101",
            subEntityId: "SE1",
            partnershipId: "PTR1",
            partnerId: "Partner1",
            interestDate: "05/01/2021",
            units: "Percentage",
            interestType: "704(b)",
            interest: "50.0%",
            transactionId: "CNTR1",
            interestReduces: "-15%",
            effectiveDate: "05/01/2021",
            effectiveInterest: "50.0%",
        },
        {
            id: "102",
            partnershipId: "PTR1",
            subEntityId: "SE2",
            partnerId: "Partner1",
            interestDate: "05/01/2021",
            units: "Percentage",
            interestType: "704(b)",
            interest: "50.0%",
            transactionId: "CNTR2",
            interestReduces: "-15%",
            effectiveDate: "05/01/2021",
            effectiveInterest: "50.0%",
        },
        {
            id: "103",
            partnershipId: "PTR4",
            subEntityId: "SE3",
            partnerId: "Partner2",
            interestDate: "10/02/2021",
            units: "Percentage",
            interestType: "704(b)",
            interest: "50%",
            transactionId: "CNTR3",
            interestReduces: "-15%",
            effectiveDate: "04/01/2021",
            effectiveInterest: "50%",
        },
        {
            id: "104",
            partnershipId: "PTR4",
            subEntityId: "SE4",
            partnerId: "Partner",
            interestDate: "20/01/2021",
            units: "Percentage",
            interestType: "704(b)",
            interest: "50.0%",
            transactionId: "CNTR4",
            interestReduces: "-15%",
            effectiveDate: "10/01/2021",
            effectiveInterest: "50.0%",
        },
    ];

    public static getCalculationsData(partnershipId: string): any[] {
        return DataHelper.CalculationData.filter((data) => data.partnershipId === partnershipId);
    }
    private static IncomeAllocatedResults: any[] =
    [{

        id:"101",

        partnershipId:"PTR1",

        partnerId:"PTNR1",

        taxYearId:"2021",

        taxPeriodId:"05/01/2021",

        incomeItemId:"INCO01",

        allocationMethod:"704(b)%",

        taxYearAmount:"100,000	",

        daysInTaxYear:"244",

        daysInTaxPeriod:"61",

        allocableAmount:"25,000",

        allocationPercentage:"50.0%",

        amountAllocated:"12,500"

    },

{

    id:"102",

    partnershipId:"PTR1",

    partnerId:"PTNR2",

    taxYearId:"2021",

    taxPeriodId:"08/01/2021",

    incomeItemId:"INCO02",

    allocationMethod:"704(b)%",

    taxYearAmount:"100,000	",

    daysInTaxYear:"244",

    daysInTaxPeriod:"67",

    allocableAmount:"25,000",

    allocationPercentage:"50.0%",

    amountAllocated:"12,500"

},

{

    id:"103",

        partnershipId:"PTR4",

    partnerId:"PTNR3",

    taxYearId:"2021",

    taxPeriodId:"10/01/2021",

    incomeItemId:"INCO03",

    allocationMethod:"704(b)%",

    taxYearAmount:"100,000	",

    daysInTaxYear:"244",

    daysInTaxPeriod:"61",

    allocableAmount:"25,000",

    allocationPercentage:"50.0%",

    amountAllocated:"12,500"

},

{

    id:"104",

        partnershipId:"PTR4",

    partnerId:"PTNR4",

    taxYearId:"2021",

    taxPeriodId:"05/01/2021",

    incomeItemId:"INCO01",

    allocationMethod:"704(b)%",

    taxYearAmount:"100,000	",

    daysInTaxYear:"244",

    daysInTaxPeriod:"61",

    allocableAmount:"25,000",

    allocationPercentage:"50.0%",

    amountAllocated:"12,500"

}]
    public static getIncomeAllocatedResultsData(partnershipId: string): any[] {
        return DataHelper.IncomeAllocatedResults.filter((data) => data.partnershipId === partnershipId);
    }

}

