import { Navigate } from "react-router-dom"
import Dashboard from "../pages/Dashboard";
import Clients from "../pages/Clients";
import Partnerships from "../pages/Partnerships";
import Partners from "../pages/Partners";
import Contributions from "../pages/Partnerships/Contributions"
import Transfers from "../pages/Partnerships/Transfers"
import Redemptions from "../pages/Partnerships/Redemptions"
import AllocationGroup from "../pages/Partnerships/AllocationGroup"
import Calculations from "../pages/Calculations/index";
import IncomeExpense from "../pages/Partnerships/IncomeExpense";
import Assets from "../pages/Partnerships/Assets";
import Results from "../pages/Results";
import Distributions from "../pages/Partnerships/Distributions";
import DistributionGroup from "../pages/Partnerships/DistributionGroup";
import Imports from "../pages/Imports"
import ClientHome from "../pages/Clients/home";
import PartnershipHome from "../pages/Partnerships/home";
import PartnerHome from "../pages/Partners/home";

// Auth
import Login from "pages/Authentication/login";
import Logout from "pages/Authentication/Logout";
import UserProfile from "pages/Authentication/user-profile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import SignUp from "pages/Authentication/Register"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/clients", component: <Clients /> },
  { path: "/partnerships", component: <Partnerships /> },
  { path: "/partners", component: <Partners /> },
  { path: "/contributions", component: <Contributions /> },
  { path: "/transfers", component: <Transfers /> },
  { path: "/redemptions", component: <Redemptions /> },
  { path: "/allocations", component: <AllocationGroup /> },
  { path: "/calculations", component: <Calculations /> },
  { path: "/income-expense", component: <IncomeExpense /> },
  { path: "/assets", component: <Assets /> },
  { path: "/results", component: <Results /> },
  { path: "/distributions", component: <Distributions /> },
  { path: "/distributiongroup", component: <DistributionGroup /> },
  { path: "/imports", component: <Imports /> },
  { path: "/clienthome", component: <ClientHome /> },
  { path: "/partnershiphome", component: <PartnershipHome /> },
  { path: "/partnerhome", component: <PartnerHome /> },



  { path: "/", exact: true, component: <Navigate to="/clients" /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <SignUp /> }
]
export { authProtectedRoutes, publicRoutes };
