import { getFirebaseBackend } from "helpers/firebase_helper";
import { postFakeLogin, postJwtLogin } from "helpers/fakebackend_helper";
import { loginSuccess, apiError, logoutUserSuccess, resetLoginFlag } from "./reducer";
import { keycloakInstance } from "../../../helpers/keycloakHelper";
import { AnyListenerPredicate } from "@reduxjs/toolkit";

export interface AuthSettings {
    profile:any;
    token:string;
    isAuthenticated:boolean;
    keycloakInitiated:boolean;
}

/**
 * The `loginuser` function handles user authentication based on the specified authentication method in
 * a TypeScript React application.
 * @param {any} user - The `user` parameter in the `loginuser` function represents the user object
 * containing the user's email and password that are used for authentication during the login process.
 * It is passed as an argument to the function when it is called.
 * @param {any} history - The `history` parameter in the `loginuser` function is typically used for
 * navigation in React applications. It allows you to programmatically navigate to different routes
 * within your application. It is commonly provided by React Router or similar routing libraries.
 */
export const loginuser = (user: any, history: any) => async (dispatch: any) => {
    try {
        let response: any;
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            let fireBaseBackend = await getFirebaseBackend();
            response = fireBaseBackend.loginUser(
                user.email,
                user.password
            )
        } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            response = await postJwtLogin({
                user: user.email,
                password: user.password
            })
        } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
            response = await postFakeLogin({
                email: user.email,
                password: user.password
            })
            localStorage.setItem("authUser", JSON.stringify(response));
            dispatch(loginSuccess(response));
        }
        else if (process.env.REACT_APP_DEFAULTAUTH === "oidc") {
            if (!keycloakInstance.authenticated) {
                keycloakInstance.init({onLoad: 'login-required'}).then(async (auth: any) => {
                    if (!auth) {
                        console.error('not authenticated');
                    } else {
                        // Attempt to refresh the token with a minimum validity of 5 seconds
                        keycloakInstance.updateToken(5).then(async () => {
                            const profile = await keycloakInstance.loadUserProfile();
                            const token = keycloakInstance.token;
                            let auth = {
                                profile,
                                token,
                                isAuthenticated: !!token,
                                keycloakInitiated: true
                            }
                            sessionStorage.setItem("authUser", JSON.stringify(auth));
                            localStorage.setItem("authUser", JSON.stringify(auth));
                            dispatch(loginSuccess(auth));
                            history('/clients',{replace: true})
                        }).catch(() => {
                            // Handle the error if the token refresh fails
                            console.error('Failed to refresh the token');
                        });
                    } 
                });
            }
          }
       // history('/clients');
    } catch (error:any) {
        dispatch(apiError(error.message));
    }
}

/**
 * The `logoutUser` function handles logging out a user based on the authentication method set in the
 * environment variables.
 */
export const logoutUser = (navigate:any) => async (dispatch: any) => {
    try {
        localStorage.removeItem("authUser");
        sessionStorage.removeItem("authUser");
        const fireBaseBackend = getFirebaseBackend();
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const response = fireBaseBackend.logout;
            dispatch(logoutUserSuccess(response));
        } else {
            if (process.env.REACT_APP_DEFAULTAUTH === "oidc")
            {
                //clear cookies 
                  await keycloakInstance?.logout({redirectUri: window.location.origin});
                dispatch(logoutUserSuccess(true));
            }else
            {
                dispatch(logoutUserSuccess(true));
            }
            
        }

    } catch (error:any) {
        dispatch(apiError(error?.message + 'error occurred in dispatch'));
    }
};

export const resetLoginMsgFlag = () => {
    try {
        const response = resetLoginFlag();
        return response;
    } catch (error) {
        return error;
    }
};


export const socialLogin = (type: any, history: any) => async (dispatch: any) => {
    try {
        let response: any;

        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const fireBaseBackend = getFirebaseBackend();
            response = fireBaseBackend.socialLoginUser(type);
        }

        const socialdata = await response;
        if (socialdata) {
            sessionStorage.setItem("authUser", JSON.stringify(socialdata));
            dispatch(loginSuccess(socialdata));
            history('/dashboard');
        }

    } catch (error:any) {
        dispatch(apiError({ message: error.message }));
    }
};