import React, {useCallback} from 'react';
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    CommandModel,
    Edit, Freeze,
    GridComponent, IEditCell,
    Inject,
    Page,
    Resize,
    Search,
    Sort,
    Toolbar,
} from "@syncfusion/ej2-react-grids";
import '../../assets/scss/datagrid.scss';
//custom generator
import {Column} from "../generator/ColumnDefGeenrator";
import * as moment from "moment";
import {DropDownList, DropDownListComponent} from '@syncfusion/ej2-react-dropdowns';
import {DataManager, Query} from "@syncfusion/ej2-data";
import {DatePicker} from "@syncfusion/ej2-calendars";
import {DialogUtility} from '@syncfusion/ej2-popups';


interface DataGridProps {
    onAdd?: (args: any) => void;
    onUpdate?: (args: any) => void;
    onDelete?: (args: any) => void;
    hasTemplate?: (args: any) => void;
    onRowClick?: (args: any) => void;
    template?: boolean;
    columnDataSources?: any;
    hasColumnDataSource?: boolean,
    dataloader?: (loaded: boolean) => void;
    id: string;
    data: any[];
    columns: any;
    editing: boolean;
    add: boolean;
    del: boolean;
    pageType?: string;
    gridTitle?: string
}

const DataGrid: React.FC<DataGridProps> = ({
                                               onAdd = () => {
                                               },
                                               onUpdate = () => {
                                               },
                                               onDelete = () => {
                                               },
                                               hasTemplate = () => {

                                               },
                                               onRowClick = () => {

                                               },
                                               id,
                                               data,
                                               columns,
                                               editing = false,
                                               add,
                                               del,
                                               template = false,
                                               hasColumnDataSource = false,
                                               columnDataSources,
                                               pageType,
                                               gridTitle
                                           }) => {


    // const gridRef:any = React.useRef<GridComponent>(null);
    const [isEditing, setIsEditing] = React.useState(editing);
    const [griddata, setGridData] = React.useState<any>([])
    const [colDataSource, setColumnDataSoure] = React.useState<any>([]);
    const [isEdit, setIsEdit] = React.useState(false);
    const [isEditMode, setIsEditMode] = React.useState(false);
    const [currentRow, setCurrentRow] = React.useState<any>();
    const [currentRowIndex, setCurrentRowIndex] = React.useState<any>();
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    // let gridInstance: any;
    const gridInstance = React.useRef<any>(null);

    let isListenerAdded = false;

    React.useEffect(() => {
        if (data && data.length > 0) {
            setGridData(data)
        }

        if (hasColumnDataSource) {
            const colDs = columnDataSources.reduce((acc: { [x: string]: any; }, cur: { accessorName: string | number; datasource: any; }) => {
                acc[cur.accessorName] = cur.datasource;
                return acc;
            }, {});

            setColumnDataSoure(colDs);

        }


    }, [columnDataSources, currentRow, data, griddata, hasColumnDataSource]);


    // This method is used to make searching on keystroke
    const created = () => {
        const searchbarElement = document.getElementById(gridInstance.current.element.id + "_searchbar") as HTMLElement;

        if (searchbarElement) {
            searchbarElement.addEventListener('keyup', (event) => {
                const value = (event.target as HTMLInputElement).value;
                console.log(value)
                if (value !== null && gridInstance !== null) {
                    gridInstance.current.search(value);
                } else {
                    console.error('Value is null or gridInstance is null');
                }
            });
        } else {
            console.error('Searchbar element not found');
        }
    };

    const handleActionComplete = useCallback((args: any) => {
      //  console.log("handleActionComplete ", args.requestType);
      //  console.log("action ", args.action);
        switch (args.requestType) {

            case 'delete':
                onDelete(args);
                break;
            case 'save':
                if (args.action === 'edit') {
                    let dialogObject = DialogUtility.confirm({
                        title: 'Confirmation',
                        content: 'Are you sure you want to save the changes?',
                        okButton: {
                            text: 'Yes', click: () => {
                                let updateData = updateColData(args)
                                onUpdate(updateData);
                                gridInstance.current.endEdit();
                                dialogObject.hide();
                            }
                        },
                        cancelButton: {
                            text: 'No', click: () => {
                                gridInstance.current.closeEdit();
                                dialogObject.hide();
                            }
                        },
                        showCloseIcon: true,
                        closeOnEscape: true
                    });


                } else if (args.action === 'add') {
                    onAdd(args);
                }
                break;

            default:

                break;
        }


    }, [onDelete, onUpdate, onAdd]);


    const updateNestedColData = (obj, column) => {
        for (let key in obj) {
            if (typeof obj[key] === 'object') {
                // console.warn("Inside update nulls .. line 160 ",obj,key,column);
                updateNestedColData(obj[key], column);
            }
        }
    }

    const updateColData = (args: any) => {
        console.log("before update: ", args.data);
        columns.forEach(column => {
            updateNestedColData(args.data, column);
        });
        return args;
    }

    // additional date processing
    const processedData = data.map(item => {
        const newItem = {...item};
        columns.forEach(col => {
            newItem[col.accessor] = item[col.accessor] === true ? 'Yes' : item[col.accessor] === false ? 'No' : item[col.accessor];
            // if (col.isDate && item[col.accessor]) {
            //     const date = new Date(item[col.accessor]);
            //     newItem[col.accessor] = date.toLocaleDateString('en-US');
            // }
        });
        return newItem;
    });


    const rowSelecting = (args: any) => {
        //console.log("rowSelecting ", args)
        //console.log("edit mode: ", isEditMode)
        if (isEditMode) {
            console.log("edit mode: true")
            args.cancel = true;
        }
    };

    const actionBegin = (args: any) => {
        // if (args.requestType === 'beginEdit' || args.requestType === 'add') {
        //     console.warn("actionBegin ", args);
        //     args?.preventDefault();
        
        //   }

        if (pageType && args.requestType === 'edit') {
            if (pageType === 'Contribution') {
                const partnerID = args.data['partnerID'];
                console.log("partnerID :", partnerID)
                if (partnerID !== undefined) {
                    const partnerIdInput = document.getElementById(partnerID) as HTMLInputElement | null;
                    if (partnerIdInput) {
                        partnerIdInput.readOnly = true;
                    } else {
                        console.error(`Element with ID "${partnerID}" not found`);
                    }
                } else {
                    console.error('partnerID not found in args.data');
                }
            }
        }


        if (pageType && args.requestType === 'add') {

            if (args.data.hasOwnProperty('subEntityID')) {
                console.warn('------------------------=======SubentityID exists in args.data');
                args.data['subEntityID'] = "";
            } else {
                console.warn('88888888888888888888 SubentityID does not exist in args.data');
            }
            if (pageType === 'Partnership')
                args.data['clientID'] = localStorage.getItem("id");

            else if (pageType === 'Partners') {
                args.data['clientID'] = localStorage.getItem("id");
                args.data['partnershipID'] = localStorage.getItem("pid");
            } else if (pageType === 'Contribution' || pageType === 'Transfer' || pageType === 'Redemption' || pageType === 'Distribution' || pageType === 'DistributionGroup' || pageType === 'Assets' || pageType === 'AllocationGroup' || pageType === 'IncomeExpense') {
                args.data['clientID'] = localStorage.getItem("id");
                args.data['partnershipID'] = localStorage.getItem("pid");
                args.data['transactionType'] = pageType;
            }
        }
        if (args.requestType === 'save') {
            // fix added for the dropdown button

            if (gridInstance.current.pageSettings.currentPage !== 1 && gridInstance.current.editSettings.newRowPosition === 'Top') {
                args.index = (gridInstance.current.pageSettings.currentPage * gridInstance.current.pageSettings.pageSize) - gridInstance.current.pageSettings.pageSize;
            } else if (gridInstance.current.editSettings.newRowPosition === 'Bottom') {
                args.index = (gridInstance.current.pageSettings.currentPage * gridInstance.current.pageSettings.pageSize) - 1;
            }
        }

    }

    function getValidationRules(column) {
        let rules: any = {};
        // rules.required = column.isRequired;

        switch (column.editType) {
            case 'defaultedit':
            case 'numericColumn':
                if (column.isRequiredConditional && currentRow !== undefined) {
                    rules.required = currentRow[column.DependentCol] === column.DependentColValue;
                    console.log("Required: ", rules.required)
                } else
                    rules.required = column.isRequired;
                break;
            case 'dropdown':
            case 'dropdownedit':
                rules.required = [column.isRequired, "Please select a Value"];
                break;
            case 'date-time': // Add this case for date edit type
                rules.required = column.isRequired;
                break;
            // Add more cases as needed
            default:
                return undefined;
        }

        switch (column.columnType) {
            case 'number':
            case 'numericColumn':
                rules.number = true;
                if (column.minLength !== undefined && column.maxLength !== undefined && column.minLength === column.maxLength) {
                    rules.minLength = column.minLength; // Add a new rule for exact length
                    rules.maxLength = column.maxLength;
                } else {
                    if (column.minLength !== undefined) {
                        rules.minLength = column.minLength;
                    }
                    if (column.maxLength !== undefined) {
                        rules.maxLength = column.maxLength;
                    }
                }
                break;
            case 'text':
                if (column.minLength !== undefined) {
                    rules.minLength = column.minLength;
                }
                if (column.maxLength !== undefined) {
                    rules.maxLength = column.maxLength;
                }
                break;
            case 'email':
                rules.email = {required: [column.isRequired, 'Enter a valid email']}
            // Add more cases as needed

        }
        //console.log(rules);
        return rules;
    }

    const idTemplate = (props: any) => {
        return hasTemplate(props);
    }

    const handleRowSelected = (args) => {
        //console.log("handleRowSelected ", args)
        setCurrentRow(args.data);
        setCurrentRowIndex(args.rowIndex);
    };

    // update the selectedValues state when the row is selected


// start of grid settings
    const commands: CommandModel[] = [];
    const editSettings = {
        initialEdit: false,
        allowEditing: isEditing,
        allowAdding: add,
        allowDeleting: del,
        mode: 'Normal',
        showConfirmDialog: true,
        showDeleteConfirmDialog: true,
        allowEditOnDblClick: false,
        allowNextRowEdit: false,
    };

    const createToolbarTemplate = (title?: string) => {
        return () => (
            title ? (
                <div style={{fontWeight: '400', marginRight: '25px', fontSize: '18px', fontFamily: 'Poppins'}}>
                    {title}
                </div>
            ) : <div style={{display: 'none', width: '0px'}}></div>  // Return an empty div if title is null or undefined
        );
    };
    const ToolbarTemplate = createToolbarTemplate(gridTitle);

    //  const toolbarOptions = ['Search'];
    const toolbarOptions: (string | { template: () => JSX.Element, align: 'Left' | 'Right' | 'Center' })[] = [
        {template: ToolbarTemplate, align: 'Left'}, // Custom template with title
        'Search', // Other predefined toolbar items
    ];

    if (editing) {
        commands.push(
            {type: 'Edit', buttonOption: {cssClass: 'e-flat', iconCss: 'e-edit e-icons'}},
            {type: 'Save', buttonOption: {cssClass: 'e-flat', iconCss: 'e-update e-icons'}},
            {type: 'Cancel', buttonOption: {cssClass: 'e-flat', iconCss: 'e-cancel-icon e-icons'}}
        );
    }

    if (add) {
        toolbarOptions.push('Add');
    }

    if (del) {
        //toolbarOptions.push('Delete');
        commands.push({type: 'Delete', buttonOption: {cssClass: 'e-flat', iconCss: 'e-delete e-icons'}});
    }
    const pageSettings = {pageSize: 20, pageSizes: true};

    // end of settings for the grid

    interface ExtendedHTMLInputElement extends HTMLInputElement {
        ej2_instances: any[];
    }



    const createDateEditCell = (accessor: any, dataSource: any[], fields: { text: string, value: string }, gridId: string): IEditCell => {
        let dateId = gridId + accessor;
        let dateValue:any;
        return {
            create: () => {
                let input = document.createElement('input');
                input.id = dateId;
                return input;
            },
            write: (args: { rowData: any, column: any, element: HTMLElement }) => {
                let inputElement = document.getElementById(dateId) as ExtendedHTMLInputElement;
                if (inputElement) {
                    let date;
                    if (args.rowData[args.column.field]) {
                        date = new Date(args.rowData[args.column.field]);
                    } else {
                        date = null; // No selection if args.rowData[args.column.field] is not defined
                    }
                    dateValue = date ? date.toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    }) : null;
                    let datePicker = new DatePicker({
                        value: dateValue,
                        format: 'MM/dd/yyyy'
                    }, inputElement);
                } else {
                    console.error('No input element found');
                }
            },
            read: (args: { rowData: any, column: any, element: HTMLElement }) => {
                let inputElement = document.getElementById(dateId) as ExtendedHTMLInputElement;
                if (inputElement) {
                    let datePicker = inputElement.ej2_instances[0] as DatePicker;
                    let dateVal = datePicker.value;
                    dateValue = dateVal?.toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    });
                    let formattedDate = dateValue;
                    return formattedDate;
                } else {
                    console.error('No input element found');
                    return null;
                }
            },
            params: {
                actionComplete: () => false,
                dataSource: new DataManager(dataSource),
                fields: fields,
                query: new Query()
            }
        };
    };

    let currentRowData: any = null;

    // const handleChange = (e:any, args:any) => {
    // //    const formObj = gridInstance.current?.editModule.formObj;
    // //    const changedAccessor = e.itemData.name; // Get the accessor from e.itemData
    // const formObj:any = gridInstance.current?.editModule?.formObj;
  

    // if (!formObj) {
    //     console.error('formObj is undefined');
    //     return;
    // }
  
    // try{
    //     columns.forEach((columnDef: any) => {
    //         console.warn("*(************************" ,formObj)
    //         if (columnDef.dependentField === args.field) {
    //          //   console.warn('Column    ', columnDef, args.field, e.value);
    //             let targetColumn = args.field;//gridInstance.current.columns[columnDef.targetField];
    //             if (targetColumn && e.value ) {

    //                 console.warn("==========================================",e.value)
    //                 const dependsOnValue = e.value;
    //                 let isRequired = columnDef.requiredValues.includes(dependsOnValue);
    //                 if (isRequired) {
    //                     if (formObj && formObj.rules && columnDef && !formObj.rules[columnDef.accessor]) {
    //                         formObj.rules[columnDef.accessor] = {};
    //                     }
    //                     if (formObj && formObj.rules && columnDef && columnDef.accessor && typeof formObj.rules[columnDef.accessor] === 'object') {
    //                         // Ensure that required is a boolean
    //                         if (typeof isRequired === 'boolean' && !formObj.rules[columnDef.accessor].required) {
    //                             formObj.rules[columnDef.accessor].required = isRequired;
    //                         }
                            
    //                         // Ensure that message is a string
    //                         const message = "This field is required!";
    //                         if (typeof message === 'string' && !formObj.rules[columnDef.accessor].message) {
    //                             formObj.rules[columnDef.accessor].message = message;
    //                         }
    //                     }
    //                 } else {
    //                     if (formObj && formObj.rules && columnDef && formObj.rules[columnDef.accessor] && typeof formObj.rules[columnDef.accessor] === 'object') {
    //                         const element = document.querySelector(`#iegrid${columnDef.accessor}`);
    //                         if (element instanceof HTMLElement) {
    //                             element.style.color = '';
    //                         }
    //                         if (formObj.rules[columnDef.accessor]) {
    //                             formObj.rules[columnDef.accessor] = {};
    //                         }
                    
    //                         // Reset the color of the field
    //                         const errorMessageElement = document.querySelector(`div#${columnDef.accessor}_Error`);
    //                         if (errorMessageElement) {
    //                             errorMessageElement.remove(); // Remove the entire error tooltip element
    //                         }
    //                     }
    //                 }
    //                 // Create a new object for formObj.rules to trigger a re-render
    //                 formObj.rules = { ...formObj.rules };
    //             }
    //         }
    //     });

    // }catch(errors){
    //         console.error(errors);
    // }
   
    // }
    window.onerror = function(message, source, lineno, colno, error) {
        console.error('An error occurred:', error,source,lineno,message);
    };

    const handleChange = (e:any, args:any) => {
        const formObj = gridInstance.current?.editModule?.formObj;

        if (!formObj) {
            console.error('formObj is undefined');
            return;
        }

        columns?.forEach((columnDef) => {
            if (columnDef?.dependentField === args?.field) {
                const targetColumn = args?.field;
                const dependsOnValue = e?.value;

                console.log(`Processing column: ${columnDef.accessor}, Target: ${targetColumn}, Value: ${dependsOnValue}`);

                if (targetColumn && dependsOnValue !== undefined) {
                    const isRequired = columnDef?.requiredValues?.includes(dependsOnValue);
                    console.warn("Rules for column accessor.....",formObj.rules[columnDef.accessor])
                    
                    formObj.rules[columnDef.accessor] = formObj.rules[columnDef.accessor] || {};

                    // Log the current state before modification
                    console.log(`Before update: `, formObj.rules[columnDef.accessor]);

                    formObj.rules[columnDef.accessor].required = isRequired;
                    if (!isRequired) {
                        const errorMessageElement = document.querySelector(`div#${columnDef.accessor}_Error`);
                        if (errorMessageElement) {
                            console.log(`Removing error message for ${columnDef.accessor}`);
                            errorMessageElement.remove();
                        } else {
                            console.log(`No error message found for ${columnDef.accessor} to remove`);
                        }
                    }

                    // Log the updated rule to see the changes
                    console.log(`After update: `, formObj.rules[columnDef.accessor]);

                    formObj.rules = { ...formObj.rules };
                } else {
                    console.warn(`Skipped processing for column: ${columnDef.accessor} due to undefined target column or value.`);
                }
            }
        });
    }




    const createEditCell = (accessor: any, dataSource: any[], fields: { text: string, value: string }, gridId: string): IEditCell => {
        let dropdownId = gridId + accessor;
        let isRequired;

        return {
            create: () => {
                let input = document.createElement('input');
                input.id = dropdownId;
                return input;
            },
            write: (args: { rowData: any, column: any, element: HTMLElement }) => {
                currentRowData = args.rowData;
                let dropdownElement = document.getElementById(dropdownId);
                if (dropdownElement) {
                    let dropdown = new DropDownList({
                        dataSource: dataSource,
                        fields: {text: fields.text, value: fields.value},
                        value: args.rowData[args.column.field],
                        change: (e) => handleChange(e, args.column)

                    }, dropdownElement);
                } else {
                    console.error('No dropdown element found');
                }
            },
            read: (args: { rowData: any, column: any, element: HTMLElement }) => {
                let dropdownElement = document.getElementById(dropdownId) as ExtendedHTMLInputElement;
                if (dropdownElement) {
                    let dropdown = dropdownElement.ej2_instances[0] as DropDownList;
                    return dropdown.value;
                } else {
                    console.error('No dropdown element found');
                    return null;
                }
            },
            params: {
                actionComplete: () => false,
                // @ts-ignore

                dataSource: new DataManager(colDataSource[accessor]),
                fields: fields,
                // @ts-ignore

                query: new Query()
            }
        };
    };
    const customDateFormat = (col, value) => {
        // console.log("Custom date format value is ", value);
        let val = value[col.accessor];
        if (typeof val !== 'string') return "";
        // Truncate the fractional seconds part
        const truncatedValue = val.split('.')[0];
        const date = new Date(truncatedValue);
        let datevalue:any;
        if(col.isDate){
            datevalue = date.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'

            });

        }

        return datevalue;
    };

    const ifilter = {params: {showSpinButton: false}};

    return (

        <GridComponent ref={gridInstance}
                       id={id}
                       pageSettings={pageSettings}
                       allowTextWrap={true}
                       textWrapSettings={{wrapMode: 'Header'}}
                       dataSource={processedData}
                       autoFit={true}
                       allowPaging={true}
                       gridLines='Both'
                       editSettings={editSettings}
                       toolbar={toolbarOptions}
                       allowResizing={false}
                       allowSorting={true}
                       actionComplete={handleActionComplete}
                       enableImmutableMode={false}
                       actionBegin={actionBegin.bind(this)}
                       created={created.bind(this)}
                       recordDoubleClick={onRowClick}
                       rowSelected={handleRowSelected.bind(this)}
                       rowSelecting={rowSelecting}

        >

            <ColumnsDirective>

                {columns.map((col: Column, index: React.Key | null | undefined) => (

                    <ColumnDirective key={index} field={col.accessor}
                                     headerTextAlign={'Center'}
                                     headerText={col.Header}
                                     textAlign="Right"
                                     autoFit={true}
                                     editType={col.editType === 'date' || col.editType === 'date-time' ? 'Datepickeredit'
                                         : col.editType === 'numericColumn' ? 'Numericedit'
                                             : col.editType === 'dropdown' ? 'dropdownedit'
                                                 : col.columnType === 'boolean' ? 'dropdownedit'
                                                     : 'DefaultEdit'}
                                     width={150}
                                     visible={col.visible} // Add this line
                                     isPrimaryKey={col.key} // Add this line
                                     allowEditing={col.editable} // Add this line
                                     format={col.editType === 'date' || col.editType === 'date-time' ? 'dd/MM/YYYY' : undefined}
                                     validationRules={getValidationRules(col)}
                                     edit={(hasColumnDataSource && (col.editType === 'dropdown' || col.editType === 'dropdownedit'))
                                         ?
                                         //console.log("Hello from");{}
                                         colDataSource[col.accessor] ?
                                             createEditCell(col.accessor, colDataSource[col.accessor], {text: "name", value: "value"}, id)
                                             : undefined
                                         : (col.editType === 'date' || col.editType === 'date-time')
                                             ?
                                             //console.log("Hello from");{}

                                             createDateEditCell(col.accessor, colDataSource[col.accessor], {text: "name", value: "value"}, id)

                                             : undefined
                                     }
                                    // template={col.template ? idTemplate : undefined}
                                    template={col.columnType === 'date-time' ? (rowData) => customDateFormat(col, rowData) : col.template ? idTemplate : undefined}
                    />
                ))}
                {editing &&
                    <ColumnDirective
                        headerTextAlign={'Center'}
                        headerText='Actions'
                        width='120'
                        commands={commands}
                        freeze={'Right'}
                        headerTemplate={(props) => (
                            <div
                                className={`frozen-column-header ${props.isFrozen ? 'e-frozenheader' : ''} custom-actions-header`}>
                                {props.headerText}
                            </div>
                        )}
                    />
                }
                {template && <ColumnDirective width={170} headerTextAlign={'Center'} template={idTemplate}/>}

            </ColumnsDirective>
            <Inject services={[Sort, Edit, Toolbar, Resize, Page, CommandColumn, Search]}/>
        </GridComponent>


    );
};

export default DataGrid;
