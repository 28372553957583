import React, {useRef, useState, useEffect} from 'react';
import {Col, Form, InputGroup} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from '../../hooks/useApi';
import {useFormik} from "formik";
import * as Yup from 'yup';
import {fetchData as clientData} from "../../BL/Clients";
import {fetchData as partnershipData} from "../../BL/Partnerships";

import {
    fetchBigBilData,
    fetchCapitalAccountData,
    fetchData,
    fetchDataIcalc, fetchDepreciationResultsData,
    fetchDistributionResultsData,
    fetchEventData, fetchAssetValuationData,
    fetchGainAllocationData, fetchGainDispositionData
} from './bl';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DataViewGrid from "../../Components/Common/DataViewGrid";
import {
    assetValuationColDef,
    bigbilColDef, depreciationResultsColDef,
    distributionResultsColDef,
    eventsColDef, gainAllocationsColDef,
    incomeAllocatedResultsColDef,
    partnerCapitalAccountColDef,
    partnerInterestColDef, gainDispositionColDef
} from "../../uihelper/gridutil";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import {fetchData as fetchClientData} from "../../BL/Clients";
const endpoints = {
    getAll: '/api/items',
    getById: '/api/items/{id}',
    addData: '/api/items',
    updateData: '/api/items/{id}',
    deleteData: '/api/items/{id}',
    uploadFile: '/api/items/{id}/upload',
};

const Results = () => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [state, setState] = useState(() => {
        const savedState = localStorage.getItem('resultsState');
        return savedState ? JSON.parse(savedState) : {
            clientId: '',
            clientList: [],
            partnershipList: [],
            partnershipId: '',
            taxYear: '',
            rowData: [],
            incomeData: [],
            eventData: [],
            capitalAcctData: [],
            bigBilData: [],
            distributionData: [],
            depreciationData: [],
            assetValuationData: [],
            gainAllocationData: [],
            gainDispositionData: [],
        };
    });

    const { clientId, clientList, partnershipList, partnershipId, taxYear, rowData, incomeData, eventData, capitalAcctData, bigBilData, distributionData, depreciationData, assetValuationData, gainAllocationData, gainDispositionData } = state;

    useEffect(() => {
        localStorage.setItem('resultsState', JSON.stringify(state));
    }, [state]);

    const { submitMultipartForm, getDataByParams } = useApi(endpoints, process.env.REACT_APP_CALCULATIONS_BASE_URL || '');

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(new FormData());

    const fetchClientData = () => {
        setLoading(true);
        clientData(getDataByParams)
            .then((clientData) => {
                if (clientData.success) {
                    setState(prevState => ({ ...prevState, clientList: clientData.data }));
                }
                setLoading(false);
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            });
    };

    const fetchPartnershipData = (currentClientId: string) => {
        setLoading(true);
        partnershipData(getDataByParams, currentClientId)
            .then((partnershipData) => {
                if (partnershipData.success) {
                    setState(prevState => ({ ...prevState, partnershipList: partnershipData.data }));
                }
                setLoading(false);
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            });
    };

    const refreshData = (values: any) => {
        setLoading(true);
        Promise.all([
            fetchData(getDataByParams, partnershipId, clientId, values.taxYear),
            fetchDataIcalc(getDataByParams, partnershipId, clientId, values.taxYear),
            fetchEventData(getDataByParams, partnershipId, clientId, values.taxYear),
            fetchCapitalAccountData(getDataByParams, partnershipId, clientId, values.taxYear),
            fetchBigBilData(getDataByParams, partnershipId, clientId, values.taxYear),
            fetchDistributionResultsData(getDataByParams, partnershipId, clientId, values.taxYear),
            fetchDepreciationResultsData(getDataByParams, partnershipId, clientId, values.taxYear),
            fetchAssetValuationData(getDataByParams, partnershipId, clientId),
            fetchGainAllocationData(getDataByParams, partnershipId, clientId, values.taxYear),
            fetchGainDispositionData(getDataByParams, partnershipId, clientId, values.taxYear),
        ])
            .then(([data1, data2, data3, data4, data5, data6, data7, data8, data9, data10]) => {
                setLoading(false);
                if (data1.success && data2.success && data3.success) {
                    setState(prevState => ({
                        ...prevState,
                        rowData: data1.data,
                        incomeData: data2.data,
                        eventData: data3.data,
                        capitalAcctData: data4.data,
                        bigBilData: data5.data,
                        distributionData: data6.data,
                        depreciationData: data7.data,
                        assetValuationData: data8.data,
                        gainAllocationData: data9.data,
                        gainDispositionData: data10.data,
                    }));
                    toast.success("Data refreshed successfully!");
                } else {
                    toast.error("No data found...");
                }
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            });
    };
    // uncomment if required

    useEffect(() => {
        fetchClientData();
        // handle unmount
        return () => {
            // cleanup
            clearData();
        }
    }, []);

    const clearData = () => {
        setState({
            ...state,
            clientId: '',
            clientList: [],
            partnershipList: [],
            partnershipId: '',
            taxYear: '',
            rowData: [],
            incomeData: [],
            eventData: [],
            capitalAcctData: [],
            bigBilData: [],
            distributionData: [],
            depreciationData: [],
            assetValuationData: [],
            gainAllocationData: [],
            gainDispositionData: [],
        });
    };

    const inLineFormikRefresh: any = useFormik({
        initialValues: {
            clientId: clientId || "",
            partnershipId: partnershipId || "",
            taxYear: taxYear || ""
        },
        validationSchema: Yup.object({
            taxYear: Yup.string().required("This field is required"),
        }),

        onSubmit: (values: any, { setSubmitting }) => {
            setState(prevState => ({ ...prevState, taxYear: values.taxYear }));
            refreshData(values);
            setSubmitting(false);
        },
    });

    // rest of the component...


    //This method should be used to submit the form data to the api along with the file
    const handleSubmitForm = async (values) => {
        setLoading(true);
        let debug = false;
        if (debug) {
            setLoading(false);
            return;
        } else {
            const endpoint = 'api/CaliculationOrchestration/run';
            formData.append('clientId', values.clientId);
            formData.append('partnershipId', values.partnershipId);
            formData.append('taxYear', values.taxYear);
            console.log(formData)
            setFormData(formData)

            try {
                const response = await submitMultipartForm(endpoint, formData, 1);
                setLoading(false);
            } catch (error) {
                // show toast notification
                setLoading(false);
                console.error('Error submitting form:', error);
            }
            setFormData(new FormData());
        }
    };

    // maps the appropriate column to fields property
    const fields = { text: 'clientName', value: 'clientID' };
    const onClientChange = (args: any) => {
        const currentClientId = args?.itemData?.clientID;
        setState(prevState => ({ ...prevState, clientId: currentClientId }));
        fetchPartnershipData(currentClientId);
    };
    const pfields = { text: 'partnershipName', value: 'partnershipID' };

    const onPartnershipChange = (args: any) => {
        if (args.itemData) {
            const currentPartnershipId = args.itemData.partnershipID;
            setState(prevState => ({ ...prevState, partnershipId: currentPartnershipId }));
        }
    };

    return (

        <div className="page-content">
            <ToastContainer/>
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader/>}
                text='Loading data...'
            >
                <br/>

                <div className="container-fluid">
                    <Form className="row row-cols-lg-auto g-3 align-items-center"
                          onSubmit={inLineFormikRefresh.handleSubmit}>
                        <Col xs={12}>
                            <InputGroup>
                                <DropDownListComponent id="atcelement" dataSource={clientList} fields={fields}
                                                       allowFiltering={true} value={clientId} placeholder="Select Client"
                                                       change={onClientChange}/>
                            </InputGroup>

                        </Col>
                        <Col xs={12}>
                            <InputGroup>
                                <DropDownListComponent id="atcelement1" dataSource={partnershipList} fields={pfields}
                                                       allowFiltering={true} value={partnershipId} placeholder="Select Partnership"
                                                       change={onPartnershipChange}/>
                            </InputGroup>

                        </Col>
                        <Col xs={12}>
                            <InputGroup>
                                <input type="text" name="taxYear" className="form-control"
                                       id="inlineFormInputGroupTaxYear" placeholder="Tax Year"
                                       value={inLineFormikRefresh.values.taxYear}
                                       onChange={inLineFormikRefresh.handleChange}
                                       onBlur={inLineFormikRefresh.handleBlur}/>
                            </InputGroup>
                            {
                                inLineFormikRefresh.errors.taxYear && inLineFormikRefresh.touched.taxYear ? (
                                    <span className="text-danger">{inLineFormikRefresh.errors.taxYear}</span>
                                ) : null
                            }
                        </Col>
                        <Col xs={12}>
                            <button type="submit" className="btn btn-primary w-md">Refresh</button>
                        </Col>
                    </Form>
                </div>
                <br/>
                <br/><br/>
                <div className="container-fluid">
                    <Tabs
                        defaultActiveKey="index1"
                        id="fill-tab-example"
                        className="mb-3 tab-container"
                        fill
                        navbar
                        navbarScroll
                    >
                        <Tab eventKey="index1" title="Events">
                            <div className="container-fluid">
                                <DataViewGrid id={"id1"}
                                              columns={eventsColDef}
                                              data={eventData}
                                              hasExport={true}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="index2" title="Partner Interests">
                            <div className="container-fluid">
                                <DataViewGrid id={"id2"}
                                              columns={partnerInterestColDef}
                                              data={rowData}
                                              hasExport={true}
                                              hasGrouping={true}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="index3" title="Capital Accounts">
                            <div className="container-fluid">
                                <DataViewGrid id={"id3"}
                                              columns={partnerCapitalAccountColDef}
                                              data={capitalAcctData}
                                              hasExport={true}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="index4" title="Asset Valuations">
                            <div className="container-fluid">
                                <DataViewGrid id="id4" columns={assetValuationColDef}
                                              data={assetValuationData}
                                              hasExport={true}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="index5" title="BIGBIL">
                            <div className="container-fluid">
                                <DataViewGrid id="id5" columns={bigbilColDef}
                                              data={bigBilData}
                                              hasExport={true}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="index6" title="Income/Expense Allocations">
                            <div className="container-fluid">
                                <DataViewGrid id="id6" columns={incomeAllocatedResultsColDef}
                                              data={incomeData}
                                              hasExport={true}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="index7" title="Distributions">
                            <div className="container-fluid">
                                <DataViewGrid id="id8" columns={distributionResultsColDef}
                                              data={distributionData}
                                              hasExport={true}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="index8" title="Depreciation">
                            <div className="container-fluid">
                                <DataViewGrid id="id8" columns={depreciationResultsColDef}
                                              data={depreciationData}
                                              hasExport={true}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="index9" title="Gain Allocation">
                            <div className="container-fluid">
                                <DataViewGrid id="id9" columns={gainAllocationsColDef}
                                              data={gainAllocationData}
                                              hasExport={true}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="index10" title="Gain Disposition">
                            <div className="container-fluid">
                                <DataViewGrid id="id10" columns={gainDispositionColDef}
                                              data={gainDispositionData}
                                              hasExport={true}
                                />
                            </div>
                        </Tab>
                    </Tabs>

                    <br/><br/>
                </div>
            </LoadingOverlay>
        </div>

    );
};

export default Results;
