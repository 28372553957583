import React, {useEffect, useRef, useState} from 'react';
import {Col} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from '../../hooks/useApi';
import DataViewGrid from "../../Components/Common/DataViewGrid";
import {importResultsColDef} from "../../uihelper/gridutil";
import DataHelper from "../../helpers/DataHelper";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";

const endpoints = {
    getAll: '/api/items',
    getById: '/api/items/{id}',
    addData: '/api/items',
    updateData: '/api/items/{id}',
    deleteData: '/api/items/{id}',
    uploadFile: '/api/items/{id}/upload',
};

const Imports = () => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [clientId, setClientId] =useLocalStorage("id", "");
    const [clientList, setClientList] = useLocalStorage("clients", "");
    const [calculationsBaseUrl] = useState<string>(process.env.REACT_APP_CALCULATIONS_BASE_URL || '');
    const {submitMultipartForm, getDataByParams} = useApi(endpoints, calculationsBaseUrl);

    const [loading, setLoading] = useState(false);
    const [importResults, setImportResults] = useState<any[]>([]);;

    useEffect(() => {

        refreshData();
    }, []);
    const refreshData = () => {

        setLoading(true);
        let dataHelper = new DataHelper();
        setImportResults(dataHelper.getImportResults());
        console.log(importResults);
        setLoading(false);
        /*Promise.all([
        ])
            .then(([]) => {
                setLoading(false);

                setLoading(false);
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });*/
    };

    const handleFileChange = async (event) => {
        event.preventDefault();
        let endPoint = "api/PartnershipCreation/CreateBulk";
        setLoading(true);
        const formData = new FormData();
        if (fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files.length > 0) {
            const file = fileInputRef.current.files[0];
            formData.append('file', file);

            try {
                const response = await submitMultipartForm(endPoint, formData, 2);
                console.log(response);

                setLoading(false);
                toast.success("File uploaded successfully");
                // fileInputRef.current.files = new FileList()
            } catch (error) {
                // show toast notification
                //   fileInputRef.current.files = new FileList()
                setLoading(false);
                console.error(error);
                toast.error("Error occurred .." + JSON.stringify(error));
            }

        } else {
            toast.error("Please select a file to upload");
            setLoading(false)
        }

    }

    let currentClientId = clientId;
    const fields = { text: 'clientName', value: 'clientID' };
    const onClientChange =(args:any)=>{
        console.log("..Client..: ", args.itemData.clientID)
        currentClientId = args.itemData.clientID;
        refreshData();
    }

    return (

        <div className="page-content">
            <ToastContainer/>
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader/>}
                text='Loading data...'
            >
                <div className="col-3">
                    <DropDownListComponent id="atcelement" dataSource={clientList} fields={fields} allowFiltering={true}
                                           value={clientId} placeholder="Change Client" change={onClientChange}/>
                </div>
                <br/>
                <div className="container-fluid">
                    <Col xs={6}>
                        <div className="input-group">
                            <input type="file" ref={fileInputRef} className="form-control" id="inputGroupFile04"
                                   aria-describedby="inputGroupFileAddon04" aria-label="Upload"/>
                            <button className="btn btn-primary" id="inputGroupFileAddon04"
                                    onClick={handleFileChange}>Upload
                            </button>
                        </div>
                    </Col>
                </div>
                <br/><br/>
                <div className="col-8">
                    <DataViewGrid id={"importResults"}
                                  columns={importResultsColDef}
                                  data={importResults}
                                  hasMultiSelect={false}
                    />
                </div>
                <br/><br/>
            </LoadingOverlay>
        </div>

    );
};

export default Imports;
